import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@services/toast.service';
import { TagsService } from '@services/tags.service';
import { CreateTagRequest } from '@models/create-tag-request';

type CreateTagForm = {
	name: FormControl<string>;
};

@Component({
	selector: 'tk-edit-tag',
	templateUrl: './edit-tag.page.html',
	styleUrls: ['/apps/technokratos-admin/src/styles/common.sass', './edit-tag.page.sass'],
})
export class EditTagPage {
	readonly tagsService = inject(TagsService);
	readonly router = inject(Router);
	readonly route = inject(ActivatedRoute);
	readonly toast = inject(ToastService);

	form = new FormGroup<CreateTagForm>({
		name: new FormControl('', {
			nonNullable: true,
			validators: [Validators.required],
		}),
	});

	tagId = inject(ActivatedRoute).snapshot.queryParams['id'];
	isSubmitted = false;

	ngOnInit(): void {
		if (this.tagId) {
			this.tagsService.getTag(this.tagId).subscribe({
				next: data => this.form.patchValue(data),
			});
		}
	}

	submit() {
		this.isSubmitted = true;

		if (this.form.invalid) {
			return;
		}

		if (this.tagId) {
			return this.tagsService.updateTag(this.tagId, this.form.value as CreateTagRequest).subscribe({
				next: () => {
					this.toast.success('Тег сохранен');
					this.goBack();
				},
				error: err => {
					this.toast.error(err.error.name[0]);
				},
			});
		}

		return this.tagsService.createTag(this.form.value as CreateTagRequest).subscribe({
			next: () => {
				this.toast.success('Тег создан');
				this.goBack();
			},
			error: err => {
				this.toast.error(err.error.name[0]);
			},
		});
	}

	goBack() {
		this.router.navigate(['tags']);
	}
}
