import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { map, Observable, of, shareReplay } from 'rxjs';
import { CacheService } from './cache.service';
import { TK_ICONS, TK_ICONS_PATH } from './icon.tokens';
import { StringHandler } from '@technokratos/components-angular/icon';
import { symbolToSvg } from '@ui/icon/icons-utils';

@Injectable({
	providedIn: 'root',
})
export class IconService {
	private cache = new Map<string, Observable<string>>();

	constructor(
		@Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,
		@Inject(TK_ICONS) private icons: Record<string, string>,
		@Inject(TK_ICONS_PATH) private path: StringHandler<string>,
		@Inject(CacheService) private readonly cacheService: CacheService,
	) {}

	getIcon(src: string): Observable<string> {
		const mappedSrc = this.icons[src] || src;

		if (mappedSrc.includes('<svg')) {
			return of(mappedSrc);
		}

		if (mappedSrc.includes('.svg')) {
			return this.cacheService.cachedRequest(mappedSrc);
		}

		return this.cacheService
			.cachedRequest(this.path(mappedSrc))
			.pipe(map(response => symbolToSvg(response, this.path(mappedSrc)) || ''));
	}

	getIconCached(src: string): Observable<string> {
		const cache = this.cache.get(src);

		if (cache) {
			return cache;
		}

		const piped = this.getIcon(src).pipe(shareReplay(1));

		this.cache.set(src, piped);

		return piped;
	}
}
