import { Validators } from '@angular/forms';

export const EmailRegExp = /^\S+@\S+\.\S+$/;
export const maxFilesize = 10 * 1024 * 1024;

export const EmailValidators = [Validators.email, Validators.pattern(EmailRegExp), Validators.maxLength(100)];

export const validateImage = (size: number): boolean => {
	return size < maxFilesize;
};
