import Header from '@editorjs/header';
import { BlockToolConstructable, EditorConfig } from '@editorjs/editorjs';
import List from '@editorjs/list';
import Delimiter from '@editorjs/delimiter';
import CodeTool from '@editorjs/code';
import InlineCode from '@editorjs/inline-code';
import Marker from '@editorjs/marker';
import ImageTool from '@editorjs/image';
import Embed from '@editorjs/embed';

import axios from 'axios';

import { TypeEnum } from '@models/enums/type.enum';
import { IncutTool } from './tools/incut';
import { InlineHeaderTool } from './tools/inline-header';
import { NumbersTool } from './tools/numbers';
import { QuoteTool } from './tools/quote';
import { AuthService } from '@services/auth.service';
import { environment } from '@environments/environment';

export const EDITORJS_TOOLS: EditorConfig = {
	holder: 'editorjs',
	inlineToolbar: ['link', 'marker', 'bold', 'inlineCode', 'italic'],
	tools: {
		header: {
			class: Header as unknown as BlockToolConstructable,
			inlineToolbar: true,
			config: {
				levels: [3, 4, 5],
				defaultLevel: 3,
			},
		},
		inlineHeader: InlineHeaderTool,
		list: {
			class: List as unknown as BlockToolConstructable,
			inlineToolbar: true,
			config: {
				defaultStyle: 'unordered',
			},
		},
		delimiter: Delimiter,
		code: CodeTool,
		inlineCode: {
			class: InlineCode,
			shortcut: 'CMD+SHIFT+M',
		},
		marker: Marker,
		incut: {
			class: IncutTool,
			inlineToolbar: true,
		},
		quote: QuoteTool,
		numbers: {
			class: NumbersTool,
			inlineToolbar: ['inlineHeader'],
		},
		embed: {
			class: Embed,
			inlineToolbar: true,
			config: {
				services: {
					youtube: true,
					instagram: true,
				},
			},
		},
		image: {
			class: ImageTool as unknown as BlockToolConstructable,
			config: {
				field: 'file',
				uploader: {
					uploadByFile(file: File) {
						return uploadEditorImage(file)
							.then((response: { data: { url?: string } }) => {
								return {
									success: 1,
									file: {
										url: response.data?.url,
									},
								};
							})
							.catch(() => {
								return {
									success: 0,
								};
							});
					},
					uploadByUrl(url: string) {
						return new Promise((resolve, reject) => {
							const img = new Image();
							img.crossOrigin = 'Anonymous';
							img.onload = () => {
								const canvas = document.createElement('canvas');
								canvas.width = img.width;
								canvas.height = img.height;
								const ctx = canvas.getContext('2d');
								if (ctx) {
									ctx.drawImage(img, 0, 0);
									canvas.toBlob(blob => {
										if (blob) {
											const file = new File([blob], 'image.png', { type: blob.type });
											uploadEditorImage(file)
												.then((response: { data: { url?: string } }) => {
													resolve({
														success: 1,
														file: {
															url: response.data?.url,
														},
													});
												})
												.catch(() => {
													reject({
														success: 0,
													});
												});
										} else {
											reject({
												success: 0,
											});
										}
									}, 'image/png');
								} else {
									reject({
										success: 0,
									});
								}
							};
							img.onerror = () => {
								reject({
									success: 0,
								});
							};
							img.src = url;
						});
					},
				},
			},
		},
	},
};

export const uploadEditorImage = (image: File) => {
	const formData = new FormData();
	formData.append('image', image);
	formData.append('type_', TypeEnum.ARTICLEIMAGE);
	const token = AuthService.getToken();

	return axios.post(`${environment.apiUrl}/admin/blog/images/`, formData, {
		headers: {
			'Authorization': `Bearer ` + token,
		},
	});
};

export const localisation = {
	messages: {
		ui: {
			blockTunes: {
				toggler: {
					'Click to tune': 'Нажмите, чтобы настроить',
					'or drag to move': 'или перетащите',
				},
			},
			inlineToolbar: {
				converter: {
					'Convert to': 'Конвертировать в',
				},
			},
			toolbar: {
				toolbox: {
					Add: 'Добавить',
				},
			},
		},
		toolNames: {
			Text: 'Параграф',
			Heading: 'Заголовок',
			List: 'Список',
			Checklist: 'Чеклист',
			Quote: 'Цитата',
			Delimiter: 'Разделитель',
			'Raw HTML': 'HTML-фрагмент',
			Table: 'Таблица',
			Link: 'Ссылка',
			Marker: 'Маркер',
			Bold: 'Полужирный',
			Italic: 'Курсив',
			InlineCode: 'Код',
			Code: 'Вставка кода',
			Image: 'Изображение',
			Incut: 'Врезка',
		},
		tools: {
			link: {
				'Add a link': 'Вставьте ссылку',
			},
			stub: {
				'The block can not be displayed correctly.': 'Блок не может быть отображен',
			},
		},
		blockTunes: {
			delete: {
				Delete: 'Удалить',
			},
			moveUp: {
				'Move up': 'Переместить вверх',
			},
			moveDown: {
				'Move down': 'Переместить вниз',
			},
		},
	},
};
