import * as i0 from '@angular/core';
import { ChangeDetectorRef, Directive, Optional, Self, Inject, Input, HostListener, NgModule } from '@angular/core';
import { AbstractTkControl } from '@technokratos/components-angular/core';
import * as i1 from '@angular/forms';
import { NgControl } from '@angular/forms';
import { filter, first } from 'rxjs/operators';
import { TextFieldModule } from '@angular/cdk/text-field';
import { TkFormFieldModule } from '@technokratos/components-angular/form-field';

let nextUniqueId = 0;
class TkInput extends AbstractTkControl {
    constructor(control, changeDetectorRef, elementRef) {
        super(control, changeDetectorRef);
        this.elementRef = elementRef;
        this.id = `tk-input-${nextUniqueId++}`;
        this.controlType = 'input';
    }
    ngOnInit() {
        // Проверка асинхронной вставки значения через ngModel
        this.ngControl?.valueChanges
            .pipe(filter(Boolean), first())
            .subscribe(() => {
            this.cdr.markForCheck();
        });
    }
    ngDoCheck() {
        this.dirtyCheckValue();
    }
    setFocus(value) {
        this.focused = value;
    }
    dirtyCheckValue() {
        const newValue = this.elementRef.nativeElement.value;
        if (newValue !== this.value) {
            this.value = newValue;
        }
    }
    containerClick() {
        this.elementRef.nativeElement.focus();
    }
}
TkInput.ɵfac = function TkInput_Factory(t) { return new (t || TkInput)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef)); };
TkInput.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: TkInput, selectors: [["input", "tkInput", ""], ["textarea", "tkInput", ""]], hostVars: 10, hostBindings: function TkInput_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("focus", function TkInput_focus_HostBindingHandler() { return ctx.setFocus(true); })("blur", function TkInput_blur_HostBindingHandler() { return ctx.setFocus(false); });
    } if (rf & 2) {
        i0.ɵɵhostProperty("disabled", ctx.disabled);
        i0.ɵɵattribute("id", ctx.id)("data-placeholder", ctx.placeholder)("aria-invalid", ctx.invalid);
        i0.ɵɵclassProp("tk-input", true)("tk-input--disabled", ctx.disabled)("tk-input--readonly", ctx.readonly);
    } }, inputs: { id: "id", placeholder: "placeholder" }, exportAs: ["tkInput"], features: [i0.ɵɵProvidersFeature([
            {
                provide: AbstractTkControl,
                useExisting: TkInput,
            },
        ]), i0.ɵɵInheritDefinitionFeature] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkInput, [{
        type: Directive,
        args: [{
                selector: 'input[tkInput], textarea[tkInput]',
                exportAs: 'tkInput',
                host: {
                    '[class.tk-input]': 'true',
                    '[class.tk-input--disabled]': 'disabled',
                    '[class.tk-input--readonly]': 'readonly',
                    '[attr.id]': 'id',
                    '[attr.data-placeholder]': 'placeholder',
                    '[disabled]': 'disabled',
                    '[attr.aria-invalid]': 'invalid',
                },
                providers: [
                    {
                        provide: AbstractTkControl,
                        useExisting: TkInput,
                    },
                ],
            }]
    }], function () { return [{ type: i1.NgControl, decorators: [{
                type: Optional
            }, {
                type: Self
            }, {
                type: Inject,
                args: [NgControl]
            }] }, { type: i0.ChangeDetectorRef, decorators: [{
                type: Inject,
                args: [ChangeDetectorRef]
            }] }, { type: i0.ElementRef }]; }, { id: [{
            type: Input
        }], placeholder: [{
            type: Input
        }], setFocus: [{
            type: HostListener,
            args: ['focus', ['true']]
        }, {
            type: HostListener,
            args: ['blur', ['false']]
        }] }); })();

class TkInputModule {
}
TkInputModule.ɵfac = function TkInputModule_Factory(t) { return new (t || TkInputModule)(); };
TkInputModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: TkInputModule });
TkInputModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [TextFieldModule, TkFormFieldModule, TextFieldModule, TkFormFieldModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkInputModule, [{
        type: NgModule,
        args: [{
                declarations: [TkInput],
                imports: [TextFieldModule, TkFormFieldModule],
                exports: [TextFieldModule, TkFormFieldModule, TkInput],
                providers: [],
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(TkInputModule, { declarations: [TkInput], imports: [TextFieldModule, TkFormFieldModule], exports: [TextFieldModule, TkFormFieldModule, TkInput] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { TkInput, TkInputModule };

