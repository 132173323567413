import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	standalone: true,
	selector: 'tk-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent {}
