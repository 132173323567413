import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { LazyImageComponent } from '@technokratos-public/components/ui/lazy-image/lazy-image.component';

@Component({
	standalone: true,
	selector: 'tk-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgSwitch, NgSwitchCase, LazyImageComponent, NgSwitchDefault],
})
export class LoaderComponent {
	@Input() width: 'FULL' | 'HALF' = 'FULL';
	@Input() color: 'BLUE' | 'WHITE' = 'BLUE';

	@HostBinding('class') get hostClass() {
		const mainClass = 'loader';

		return [mainClass, `${mainClass}--${this.width.toLowerCase()}`];
	}
}
