/**
 *
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type StatusEnum = 'PUBLISHED' | 'DRAFT';

export const StatusEnum = {
	PUBLISHED: 'PUBLISHED' as StatusEnum,
	DRAFT: 'DRAFT' as StatusEnum,
};
