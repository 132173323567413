import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationErrors } from '@angular/forms';

@Component({
	selector: 'tk-form-field-error',
	standalone: true,
	templateUrl: './form-field-error.component.html',
	styleUrl: './form-field-error.component.sass',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule],
})
export class FormFieldErrorComponent {
	@Input() errors?: ValidationErrors | null;
}
