import { inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, fromEvent, map, takeUntil } from 'rxjs';
import { DestroyService } from '@services/destroy.service';
import { isPlatformBrowser } from '@angular/common';
import { WINDOW } from '@ng-web-apis/common';

@Injectable({
	providedIn: 'root',
	deps: [DestroyService],
})
export class WindowScrollService implements OnDestroy {
	scrollY$ = new BehaviorSubject(0);

	constructor() {
		const destroy$ = inject(DestroyService);
		const _window = inject(WINDOW);

		if (isPlatformBrowser(inject(PLATFORM_ID))) {
			fromEvent(_window, 'scroll')
				.pipe(
					map(() => _window.scrollY),
					takeUntil(destroy$),
				)
				.subscribe(scrollPosition => this.scrollY$.next(scrollPosition));
		}
	}

	ngOnDestroy() {
		this.scrollY$.complete();
	}
}
