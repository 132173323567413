import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Tag } from '@models/tag';
import { CategoriesEnum } from '@models/enums/category.enum';
import { CreateTagRequest } from '@models/create-tag-request';

@Injectable({
	providedIn: 'root',
})
export class TagsService implements OnDestroy {
	tagList$ = new BehaviorSubject<Tag[]>([]);

	private http = inject(HttpClient);

	getTags() {
		return this.http.get<Tag[]>('admin/blog/tags/');
	}

	getPublicTags(category?: CategoriesEnum) {
		let params = new HttpParams();
		if (category) {
			params = params.append('category', category.toUpperCase());
		}

		return this.http.get<Tag[]>('blog/tags/', { params });
	}

	getTag(id: string) {
		return this.http.get<Tag>(`admin/blog/tags/${id}/`);
	}

	createTag(data: CreateTagRequest) {
		return this.http.post<Tag>('admin/blog/tags/', data);
	}

	updateTag(id: number, data: Partial<CreateTagRequest>) {
		return this.http.patch<Tag>(`admin/blog/tags/${id}/`, data);
	}

	deleteTag(id: number) {
		return this.http.delete<void>(`admin/blog/tags/${id}/`);
	}

	ngOnDestroy() {
		this.tagList$.complete();
	}
}
