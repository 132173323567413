import { Component, HostBinding, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { BooleanInput, coerceBooleanProperty, NumberInput } from '@angular/cdk/coercion';

@Component({
	selector: 'tk-lazy-image',
	standalone: true,
	templateUrl: './lazy-image.component.html',
	styleUrl: './lazy-image.component.sass',
	imports: [NgOptimizedImage, NgClass],
})
export class LazyImageComponent {
	@Input() alt = '';
	@Input() width: NumberInput = 0;
	@Input() height: NumberInput = 0;
	@HostBinding('style.--object-fit') @Input() objectFit: 'cover' | 'fill' | 'contain' | 'none' = 'cover';

	@ViewChild('browserImage') browserImage!: TemplateRef<unknown>;
	@ViewChild('serverImage') serverImage!: TemplateRef<unknown>;

	private _pathToImage = '';

	@Input({ required: true })
	get pathToImage(): string {
		return this._pathToImage;
	}

	set pathToImage(value: string) {
		if (value && value !== this.pathToImage) {
			this._pathToImage = `assets/images/${value}`;
		}
	}

	private _fill = false;

	@Input()
	@HostBinding('class.background')
	get fill(): boolean {
		return this._fill;
	}

	set fill(value: BooleanInput) {
		const newValue = coerceBooleanProperty(value);
		if (newValue !== this.fill) {
			this._fill = newValue;
		}
	}

	private _absolutePosition = false;

	@Input()
	@HostBinding('class.absolute-position')
	get absolutePosition(): boolean {
		return this._absolutePosition;
	}

	set absolutePosition(value: BooleanInput) {
		const newValue = coerceBooleanProperty(value);
		if (newValue !== this.absolutePosition) {
			this._absolutePosition = newValue;
		}
	}

	private _priority = false;

	@Input()
	get priority(): boolean {
		return this._priority;
	}

	set priority(value: BooleanInput) {
		this._priority = coerceBooleanProperty(value);
	}
}
