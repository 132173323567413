import * as i0 from '@angular/core';
import { InjectionToken, Directive, Optional, Input, EventEmitter, Component, ViewEncapsulation, ChangeDetectionStrategy, Inject, ContentChildren, Output, HostListener, NgModule } from '@angular/core';
import { startWith, switchMap, takeUntil, debounceTime } from 'rxjs/operators';
import * as i1 from '@angular/forms';
import { Subject, merge } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { noop } from '@technokratos/components-angular/core';
import { TkSuffix } from '@technokratos/components-angular/form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';

function TkRadioButton_div_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵprojection(1, 1);
    i0.ɵɵelementEnd();
} }
const _c0 = ["*", [["tk-suffix"], ["", "tkSuffix", ""]]];
const _c1 = ["*", "tk-suffix, [tkSuffix]"];
const TK_RADIO_GROUP = new InjectionToken('TkRadioGroup');
class TkRadioGroup {
    constructor(cdr, ngControl) {
        this.cdr = cdr;
        this.ngControl = ngControl;
        this.theme = 'inline';
        this.direction = 'vertical';
        this.options = [];
        this.options$ = new Subject();
        this.optionSelectionChanges = this.options$.pipe(startWith(this.options), switchMap(() => merge(...this.options.map(option => option.itemClick))));
        this.destroy$ = new Subject();
        this.onTouchedCallback = noop;
        this.onChangeCallback = noop;
        this._reverse = false;
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
        this.selectionModel = new SelectionModel(false);
    }
    get reverse() {
        return this._reverse;
    }
    set reverse(value) {
        this._reverse = coerceBooleanProperty(value);
    }
    get selected() {
        return this.selectionModel.selected;
    }
    register(checkbox) {
        this.options = [...this.options, checkbox];
        this.options$.next(this.options);
    }
    deregister(checkbox) {
        this.options = this.options.filter(o => o !== checkbox);
        this.options$.next(this.options);
    }
    ngAfterContentInit() {
        this.selectionModel.changed
            .pipe(takeUntil(this.destroy$))
            .subscribe(event => {
            event.added.forEach(option => option.select());
            event.removed.forEach(option => option.deselect());
        });
        this.options$
            .pipe(startWith(null), debounceTime(0), takeUntil(this.destroy$))
            .subscribe(() => {
            this.resetOptions();
            this.updateSelectionModel();
        });
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
    registerOnChange(fn) {
        this.onChangeCallback = fn;
    }
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    writeValue(value) {
        this.value = value;
        this.updateSelectionModel();
        this.cdr.markForCheck();
    }
    updateSelectionModel() {
        if (!this.selectionModel) {
            return;
        }
        this.selectionModel.clear();
        const selectedOptions = this.options.filter(option => this.value === option.value);
        this.selectionModel.select(...selectedOptions);
    }
    resetOptions() {
        this.optionSelectionChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(event => {
            this._onSelect(event);
        });
    }
    _onSelect(option) {
        const wasSelected = this.selectionModel.isSelected(option);
        if (wasSelected !== option.checked) {
            option.checked
                ? this.selectionModel.select(option)
                : this.selectionModel.deselect(option);
        }
        if (wasSelected !== this.selectionModel.isSelected(option)) {
            this.propagateChanges();
        }
    }
    propagateChanges() {
        const valueToEmit = this.selected.map(option => option.value);
        if (valueToEmit) {
            this.onChangeCallback(valueToEmit[0]);
            this.cdr.markForCheck();
        }
    }
}
TkRadioGroup.ɵfac = function TkRadioGroup_Factory(t) { return new (t || TkRadioGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.NgControl, 8)); };
TkRadioGroup.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: TkRadioGroup, selectors: [["tk-radio-group"]], hostAttrs: ["role", "radiogroup", 1, "tk-radio-group"], hostVars: 8, hostBindings: function TkRadioGroup_HostBindings(rf, ctx) { if (rf & 2) {
        i0.ɵɵclassProp("tk-radio-group--inline", ctx.theme === "inline")("tk-radio-group--filled", ctx.theme === "filled")("tk-radio-group--vertical", ctx.direction === "vertical")("tk-radio-group--horizontal", ctx.direction === "horizontal");
    } }, inputs: { theme: "theme", direction: "direction", reverse: "reverse" }, exportAs: ["tkRadioGroup"], features: [i0.ɵɵProvidersFeature([{ provide: TK_RADIO_GROUP, useExisting: TkRadioGroup }])] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkRadioGroup, [{
        type: Directive,
        args: [{
                selector: 'tk-radio-group',
                exportAs: 'tkRadioGroup',
                providers: [{ provide: TK_RADIO_GROUP, useExisting: TkRadioGroup }],
                host: {
                    'role': 'radiogroup',
                    'class': 'tk-radio-group',
                    '[class.tk-radio-group--inline]': 'theme === "inline"',
                    '[class.tk-radio-group--filled]': 'theme === "filled"',
                    '[class.tk-radio-group--vertical]': 'direction === "vertical"',
                    '[class.tk-radio-group--horizontal]': 'direction === "horizontal"',
                },
            }]
    }], function () { return [{ type: i0.ChangeDetectorRef }, { type: i1.NgControl, decorators: [{
                type: Optional
            }] }]; }, { theme: [{
            type: Input
        }], direction: [{
            type: Input
        }], reverse: [{
            type: Input
        }] }); })();
class TkRadioButton {
    constructor(cdr, group) {
        this.cdr = cdr;
        this.group = group;
        this.itemClick = new EventEmitter();
        this._theme = 'inline';
        this._checked = false;
        this._disabled = false;
        this._reverse = false;
    }
    get theme() {
        return this.group.theme || this._theme;
    }
    set theme(value) {
        this._theme = value;
    }
    get checked() {
        return this._checked;
    }
    set checked(value) {
        this._checked = coerceBooleanProperty(value);
    }
    get disabled() {
        return this._disabled;
    }
    set disabled(value) {
        this._disabled = coerceBooleanProperty(value);
    }
    get reverse() {
        return this.group.reverse || this._reverse;
    }
    set reverse(value) {
        this._reverse = coerceBooleanProperty(value);
    }
    get hasSuffix() {
        return this.suffix.length > 0;
    }
    ngOnInit() {
        this.group?.register(this);
    }
    ngAfterContentInit() {
        this.suffix.changes.subscribe(() => this.cdr.markForCheck());
    }
    ngOnDestroy() {
        this.group?.deregister(this);
    }
    select() {
        if (!this.checked) {
            this.checked = true;
            this.itemClick.emit(this);
            this.cdr.markForCheck();
        }
    }
    deselect() {
        if (this.checked) {
            this.checked = false;
            this.itemClick.emit(this);
        }
    }
}
TkRadioButton.ɵfac = function TkRadioButton_Factory(t) { return new (t || TkRadioButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(TK_RADIO_GROUP, 8)); };
TkRadioButton.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TkRadioButton, selectors: [["tk-radio-button"]], contentQueries: function TkRadioButton_ContentQueries(rf, ctx, dirIndex) { if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, TkSuffix, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.suffix = _t);
    } }, hostAttrs: [1, "tk-radio-button"], hostVars: 9, hostBindings: function TkRadioButton_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("click", function TkRadioButton_click_HostBindingHandler() { return ctx.select(); });
    } if (rf & 2) {
        i0.ɵɵattribute("tabindex", -1);
        i0.ɵɵclassProp("tk-radio-button--checked", ctx.checked)("tk-radio-button--disabled", ctx.disabled)("tk-radio-button--inline", ctx.theme === "inline")("tk-radio-button--filled", ctx.theme === "filled");
    } }, inputs: { value: "value", theme: "theme", checked: "checked", disabled: "disabled", reverse: "reverse" }, outputs: { itemClick: "itemClick" }, exportAs: ["tkRadioButton"], ngContentSelectors: _c1, decls: 7, vars: 5, consts: [[1, "tk-radio-button__wrapper"], [1, "tk-radio-button__circle"], [1, "tk-radio-button__circle-outer"], [1, "tk-radio-button__circle-inner"], [1, "tk-radio-button__children"], ["class", "tk-radio-button__suffix", 4, "ngIf"], [1, "tk-radio-button__suffix"]], template: function TkRadioButton_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef(_c0);
        i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
        i0.ɵɵelement(2, "div", 2)(3, "div", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵprojection(5);
        i0.ɵɵelementEnd()();
        i0.ɵɵtemplate(6, TkRadioButton_div_6_Template, 2, 0, "div", 5);
    } if (rf & 2) {
        i0.ɵɵclassProp("tk-radio-button__wrapper--reverse", ctx.reverse)("tk-radio-button__wrapper--not-reverse", !ctx.reverse);
        i0.ɵɵadvance(6);
        i0.ɵɵproperty("ngIf", ctx.hasSuffix);
    } }, dependencies: [i2.NgIf], encapsulation: 2, changeDetection: 0 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkRadioButton, [{
        type: Component,
        args: [{ selector: 'tk-radio-button', encapsulation: ViewEncapsulation.None, exportAs: 'tkRadioButton', host: {
                    'class': 'tk-radio-button',
                    '[class.tk-radio-button--checked]': 'checked',
                    '[class.tk-radio-button--disabled]': 'disabled',
                    '[class.tk-radio-button--inline]': 'theme === "inline"',
                    '[class.tk-radio-button--filled]': 'theme === "filled"',
                    '[attr.tabindex]': '-1',
                }, changeDetection: ChangeDetectionStrategy.OnPush, template: "<div\n\tclass=\"tk-radio-button__wrapper\"\n\t[class.tk-radio-button__wrapper--reverse]=\"reverse\"\n\t[class.tk-radio-button__wrapper--not-reverse]=\"!reverse\"\n>\n\t<div class=\"tk-radio-button__circle\">\n\t\t<div class=\"tk-radio-button__circle-outer\"></div>\n\t\t<div class=\"tk-radio-button__circle-inner\"></div>\n\t</div>\n\n\t<div class=\"tk-radio-button__children\">\n\t\t<ng-content></ng-content>\n\t</div>\n</div>\n<div class=\"tk-radio-button__suffix\" *ngIf=\"hasSuffix\">\n\t<ng-content select=\"tk-suffix, [tkSuffix]\"></ng-content>\n</div>\n" }]
    }], function () { return [{ type: i0.ChangeDetectorRef }, { type: undefined, decorators: [{
                type: Optional
            }, {
                type: Inject,
                args: [TK_RADIO_GROUP]
            }] }]; }, { value: [{
            type: Input
        }], suffix: [{
            type: ContentChildren,
            args: [TkSuffix, { descendants: true }]
        }], itemClick: [{
            type: Output
        }], theme: [{
            type: Input
        }], checked: [{
            type: Input
        }], disabled: [{
            type: Input
        }], reverse: [{
            type: Input
        }], select: [{
            type: HostListener,
            args: ['click']
        }] }); })();

class TkRadioModule {
}
TkRadioModule.ɵfac = function TkRadioModule_Factory(t) { return new (t || TkRadioModule)(); };
TkRadioModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: TkRadioModule });
TkRadioModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkRadioModule, [{
        type: NgModule,
        args: [{
                imports: [CommonModule],
                exports: [TkRadioGroup, TkRadioButton],
                declarations: [TkRadioGroup, TkRadioButton],
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(TkRadioModule, { declarations: [TkRadioGroup, TkRadioButton], imports: [CommonModule], exports: [TkRadioGroup, TkRadioButton] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { TK_RADIO_GROUP, TkRadioButton, TkRadioGroup, TkRadioModule };

