import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	standalone: true,
	selector: 'tk-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {}
