import { ApplicationConfig } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { UrlInterceptor } from '@services/url-interceptor.service';
import { AuthInterceptor } from '@services/auth.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import { DEFAULT_TOAST_CONFIG } from '@models/constants/toast-config';

export const appConfig: ApplicationConfig = {
	providers: [
		provideRouter(routes, withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })),
		provideClientHydration(),
		provideHttpClient(withFetch(), withInterceptors([UrlInterceptor, AuthInterceptor])),
		provideAnimations(),
		provideToastr(DEFAULT_TOAST_CONFIG),
	],
};
