import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HeaderTheme } from '@models/enums/header-theme.enum';

@Injectable({
	providedIn: 'root',
})
export class CookieThemeService implements OnDestroy {
	private _theme$ = new BehaviorSubject<HeaderTheme>(HeaderTheme.DARK);

	get theme() {
		return this._theme$;
	}

	changeTheme(theme: HeaderTheme | undefined) {
		if (theme) this._theme$.next(theme);
	}

	ngOnDestroy() {
		this._theme$.complete();
	}
}
