import { inject, Injectable } from '@angular/core';
import { Article } from '@models/article';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ArticleCreateUpdateRequest } from '@models/article-create-update-request';
import { CategoriesEnum } from '@models/enums/category.enum';

@Injectable({
	providedIn: 'root',
})
export class ArticlesService {
	private http = inject(HttpClient);

	getArticles() {
		return this.http.get<Article[]>('admin/blog/articles/');
	}

	getPublishedArticles(category?: CategoriesEnum | null, tag?: string | null) {
		let params = new HttpParams();

		category && (params = params.append('category', category.toUpperCase()));
		tag && (params = params.append('tags', tag));

		return this.http.get<Article[]>('blog/articles/', { params });
	}

	getArticle(id: string) {
		return this.http.get<Article>(`admin/blog/articles/${id}/`);
	}

	getPublicArticle(id: string) {
		return this.http.get<Article>(`blog/articles/${id}/`);
	}

	createArticle(data: ArticleCreateUpdateRequest) {
		return this.http.post<Article>('admin/blog/articles/', data);
	}

	editArticle(id: number, data: Article) {
		return this.http.put<Article>(`admin/blog/articles/${id}/`, data);
	}

	updateArticle(id: number, data: Partial<Article>) {
		return this.http.patch<Article>(`admin/blog/articles/${id}/`, data);
	}

	deleteArticle(id: number) {
		return this.http.delete<void>(`admin/blog/articles/${id}/`);
	}
}
