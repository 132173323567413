import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostListener,
	inject,
	OnDestroy,
	OnInit,
	signal,
	ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ArticlesService } from '@services/articles.service';
import { combineLatest, debounceTime, Observable, takeUntil } from 'rxjs';
import EditorJS from '@editorjs/editorjs';
import { AuthorsService } from '@services/authors.service';
import { TagsService } from '@services/tags.service';
import { EDITORJS_TOOLS, localisation } from '@pages-admin/articles/article-edit/utils/editor.config';
import { ImageService } from '@technokratos-admin/services/image.service';
import { TypeEnum } from '@models/enums/type.enum';
import { StatusEnum } from '@models/enums/status.enum';
import { PreviewModalContainerComponent } from '@technokratos-admin/components/modals/preview-modal-container/preview-modal-container.component';
import { AdminPreviewComponent } from '@pages-admin/articles/article-edit/components/preview/preview.component';
import { DestroyService } from '@services/destroy.service';
import { Categories } from '@models/categories';
import { DialogService } from '@services/dialog.service';
import { ToastService } from '@services/toast.service';
import { Article } from '@models/article';
import { Image } from '@models/image';

@Component({
	selector: 'tk-article-edit',
	templateUrl: './article-edit.page.html',
	styleUrls: ['./article-edit.page.sass', '/apps/technokratos-admin/src/styles/common.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleEditPage implements OnInit, OnDestroy {
	@ViewChild('title') titleInput: ElementRef | undefined;
	@ViewChild('editor') editorRef: ElementRef | undefined;

	private fb = inject(FormBuilder);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private articlesService = inject(ArticlesService);
	private authorsService = inject(AuthorsService);
	private tagsService = inject(TagsService);
	private imageService = inject(ImageService);
	private toast = inject(ToastService);
	private dialogService = inject(DialogService);
	private destroy$ = inject(DestroyService);

	form: FormGroup = this.fb.group({
		title: [null, [Validators.required]],
		subtitle: [null, [Validators.required]],
		cover: [null, [Validators.required]],
		authors: [null, [Validators.required]],
		tags: [null, [Validators.required]],
		category: [null, [Validators.required]],
		is_shown_by_link: [false],
	});

	id?: string | null = null;
	isNewArticle = true;
	isSubmitted = false;
	article?: Article | null = null;

	cover: string | null = this.form.get('cover')!.value;

	authors$ = this.authorsService.authorList$;
	tags$ = this.tagsService.tagList$;

	categories = Categories;
	categoryList = ['HARDSKILL', 'STORY', 'TRANSLATIONS', 'PROCESSES', 'CASES'];

	editorData: string | null = null;
	initEditorData: string | null = null;
	editor: EditorJS | null = null;
	editorObserver: MutationObserver | null = null;
	isEditorChanging = signal<boolean>(false);

	statuses = StatusEnum;

	constructor() {
		this.id = this.route.snapshot.params['articleId'];

		combineLatest([this.authorsService.getAuthors(), this.tagsService.getTags()])
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: ([authors, tags]) => {
					this.authors$.next(authors);
					this.tags$.next(tags);
				},
			});

		if (this.id) {
			this.isNewArticle = false;
			this.articlesService
				.getArticle(this.id)
				.pipe(takeUntil(this.destroy$))
				.subscribe((data: Article) => {
					this.article = data;
					this.form.patchValue({ ...data });
					this.cover = this.form.get('cover')!.value;
					this.editor = new EditorJS({
						...EDITORJS_TOOLS,
						i18n: {
							...localisation,
						},
						data: data.content ? JSON.parse(data.content) : null,
					});
					this.initEditorData = JSON.parse(data.content).blocks;
				});
		} else {
			this.editor = new EditorJS({
				...EDITORJS_TOOLS,
				i18n: {
					...localisation,
				},
			});
		}
	}

	ngOnInit(): void {
		this.detectEditorChanges()
			.pipe(debounceTime(200), takeUntil(this.destroy$))
			.subscribe(() => {
				this.editor!.save().then(outputData => {
					this.isEditorChanging.set(
						JSON.stringify(this.initEditorData)?.replace(/\s+/g, '') !==
							JSON.stringify(outputData.blocks, null, 2)?.replace(/\s+/g, ''),
					);
					this.editorData = JSON.stringify(outputData, null, 2);
				});
			});
	}

	detectEditorChanges(): Observable<MutationRecord[]> {
		return new Observable(observer => {
			const editorDom = document.querySelector('#editorjs');
			const config = { attributes: true, childList: true, subtree: true };

			this.editorObserver = new MutationObserver(mutation => {
				observer.next(mutation);
			});
			this.editorObserver.observe(editorDom!, config);
		});
	}

	uploadPreview = (event: Event): void => {
		const file = (event.target as HTMLInputElement)?.files?.[0];

		if (!file) {
			return;
		}

		this.imageService
			.uploadImage(file, TypeEnum.ARTICLECOVER)
			.pipe(takeUntil(this.destroy$))
			.subscribe((data: Image) => {
				this.cover = data.url;
				this.form.setValue({ ...this.form.value, cover: data.url });

				setTimeout(() => {
					this.titleInput!.nativeElement.focus();
				}, 100);
			});
	};

	openArticlePreview = (): void => {
		let date = null;
		if (this.article?.published_at) {
			const [day, month, year] = this.article.published_at.split('.');
			date = new Date(`${year}-${month}-${day}`);
		}
		const previewContent = {
			...this.form.value,
			published_at: date || new Date(),
			authors: this.form.value.authors.map((id: number) => this.authors$.value.find(author => author.id === id)),
			tags: this.form.value.tags.map((id: number) => this.tags$.value.find(tag => tag.id === id)!.name),
		};

		this.dialogService.openDialog(AdminPreviewComponent, {
			width: '1160px',
			container: PreviewModalContainerComponent,
			data: {
				preview: true,
				previewData: previewContent,
				content: this.editorData,
			},
		});
	};

	saveArticle = () => {
		this.submitForm(this.article ? this.article.status : StatusEnum.DRAFT);
	};

	submitForm = (status: StatusEnum) => {
		this.isSubmitted = true;

		if (this.form.invalid) {
			return;
		}

		const content = {
			...this.form.value,
			content: this.editorData,
			pinned: false,
			status: status,
		};

		if (this.id) {
			this.articlesService
				.editArticle(+this.id!, content)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: () => {
						this.toast.success('Статья успешно обновлена');
						this.isEditorChanging.set(false);
						this.form.markAsPristine();
						this.router.navigate(['/articles']);
					},
					error: err => this.toast.error(err),
				});
		} else {
			this.articlesService
				.createArticle(content)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: () => {
						this.toast.success('Статья создана');
						this.isEditorChanging.set(false);
						this.form.markAsPristine();
						this.router.navigate(['/articles']);
					},
					error: err => this.toast.error(err),
				});
		}
	};

	ngOnDestroy(): void {
		this.editorObserver?.disconnect();
	}

	get isDirty() {
		return this.form.dirty || this.isEditorChanging();
	}

	@HostListener('window:beforeunload')
	canDeactivate(): boolean {
		return !this.form.dirty && !this.isEditorChanging();
	}

	goBack() {
		this.router.navigate(['/articles']);
	}
}
