import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Mail } from '@models/mail';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MailSubscribeRequest } from '@models/mail-subscribe-request';
import { AgonaRequest } from '@models/agona-request';
import { Departments } from '@models/departments';

@Injectable({
	providedIn: 'root',
})
export class MailService implements OnDestroy {
	emailList$ = new BehaviorSubject<Mail[]>([]);

	private http = inject(HttpClient);

	exportEmails() {
		return this.http.get('admin/blog/mailing/export/', { observe: 'response', responseType: 'blob' });
	}

	getEmails(sort: string) {
		const params = new HttpParams().set('sort', sort);
		return this.http.get<Mail[]>('admin/blog/mailing/', { params });
	}

	subscribe(data: MailSubscribeRequest) {
		return this.http.post<Mail>('blog/mailing/', data);
	}

	getDepartments() {
		return this.http.get<Departments>('mailing/departments/');
	}

	agonaSubscribe(data: AgonaRequest) {
		return this.http.post<AgonaRequest>('mailing/agona/', data);
	}

	ngOnDestroy() {
		this.emailList$.complete();
	}
}
