import { NgModule } from '@angular/core';
import { DialogCloseDirective } from './src/dialog-close.directive';
import { ScrollHeaderDirective } from './src/scroll-header.directive';
import { LetDirective } from '@directives/src/let.directive';

@NgModule({
	imports: [ScrollHeaderDirective, LetDirective, DialogCloseDirective],
	exports: [DialogCloseDirective, ScrollHeaderDirective, LetDirective],
})
export class DirectivesModule {}
