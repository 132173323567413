<div class="panel__message">
	<p class="panel__text">
		Мы&nbsp;используем cookie, чтобы сделать наш сайт ещё лучше. Продолжая использовать сайт,
		<br />
		вы даёте согласие на их обработку на условиях
		<a routerLink="/privacy-policy" target="_blank" class="panel__text--link">Политики конфиденциальности</a>
	</p>
</div>
<button (click)="acceptCookies()" [theme]="getButtonTheme" class="panel__button" tkButton>Молодцы</button>
<button [tkDialogClose]="true" [class.panel__cross--light]="getIsLight" class="panel__cross">
	<tk-icon icon="cross" size="24" />
</button>
