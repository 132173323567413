import { inject } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { CookiesModalComponent } from '@technokratos-public/components/modals/cookies-modal/cookies-modal.component';

export const openedDialogs = () => {
	const dialog = inject(Dialog);

	return () => {
		return (
			dialog.openDialogs.length &&
			!dialog.openDialogs.find(dialog => dialog.componentInstance instanceof CookiesModalComponent)
		);
	};
};
