import { Component, ElementRef, EventEmitter, Inject, ViewChild } from '@angular/core';
import { Dialog, DIALOG_DATA } from '@angular/cdk/dialog';

@Component({
	selector: 'tk-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styleUrls: ['./confirm-modal.component.sass', '/apps/technokratos-admin/src/styles/common.sass'],
})
export class ConfirmModalComponent {
	onSubmit = new EventEmitter();
	title: string | null = null;
	btnText: string | null = null;

	@ViewChild('name') nameInput: ElementRef | undefined;

	constructor(
		private dialog: Dialog,
		@Inject(DIALOG_DATA) data: { title: string; btn: string },
	) {
		this.title = data.title;
		this.btnText = data.btn;
	}

	submitForm = () => {
		this.onSubmit.emit(true);
	};

	closeModal = () => {
		this.dialog.closeAll();
	};
}
