import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, EventEmitter, Component, ChangeDetectionStrategy, ViewEncapsulation, Optional, Inject, Input, HostListener, Output, ViewChild, NgModule } from '@angular/core';
import * as i2 from '@technokratos/components-angular/icon';
import { TkIconModule } from '@technokratos/components-angular/icon';
import * as i3 from '@technokratos/components-angular/checkbox';
import { TkCheckboxModule } from '@technokratos/components-angular/checkbox';
import * as i4 from '@angular/forms';
import { FormsModule } from '@angular/forms';

const OptionParentToken = new InjectionToken('OptionParent');

const _c0 = ["content"];
function TkOption_tk_checkbox_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "tk-checkbox", 4);
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngModel", ctx_r0.selected)("disableClick", true);
} }
function TkOption_tk_icon_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "tk-icon", 5);
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("tk-option__check-icon--visible", ctx_r2.selected);
} }
const _c1 = ["*"];
class TkOption {
    constructor(parent, cdr, el) {
        this.parent = parent;
        this.cdr = cdr;
        this.el = el;
        this.selected = false;
        this.multiple = false;
        this.itemClick = new EventEmitter();
    }
    ngOnInit() {
        if (this.parent?.multiple) {
            this.multiple = true;
        }
        this.parent?.register(this);
    }
    ngOnDestroy() {
        this.parent?.deregister(this);
    }
    focus() {
        this.el.nativeElement.focus();
    }
    onClick() {
        this.itemClick.emit(this);
    }
    get viewValue() {
        return this.content
            ? (this.content.nativeElement.textContent || '').trim()
            : '';
    }
}
TkOption.ɵfac = function TkOption_Factory(t) { return new (t || TkOption)(i0.ɵɵdirectiveInject(OptionParentToken, 8), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef)); };
TkOption.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TkOption, selectors: [["tk-option"]], viewQuery: function TkOption_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
    } }, hostAttrs: ["tabindex", "0"], hostVars: 6, hostBindings: function TkOption_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("class.tk-option--disabled", function TkOption_class_tk_option__disabled_HostBindingHandler() { return ctx.disabled(); })("click", function TkOption_click_HostBindingHandler() { return ctx.onClick(); });
    } if (rf & 2) {
        i0.ɵɵclassProp("tk-option", true)("tk-option--selected", !ctx.multiple && ctx.selected)("tk-option--multiple", ctx.multiple);
    } }, inputs: { selected: "selected", value: "value", disabled: "disabled", multiple: "multiple" }, outputs: { itemClick: "itemClick" }, ngContentSelectors: _c1, decls: 5, vars: 2, consts: [[3, "ngModel", "disableClick", 4, "ngIf"], [1, "tk-option__content"], ["content", ""], ["icon", "tkCheck", "class", "tk-option__check-icon", 3, "tk-option__check-icon--visible", 4, "ngIf"], [3, "ngModel", "disableClick"], ["icon", "tkCheck", 1, "tk-option__check-icon"]], template: function TkOption_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵtemplate(0, TkOption_tk_checkbox_0_Template, 1, 2, "tk-checkbox", 0);
        i0.ɵɵelementStart(1, "div", 1, 2);
        i0.ɵɵprojection(3);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(4, TkOption_tk_icon_4_Template, 1, 2, "tk-icon", 3);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.multiple);
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("ngIf", !ctx.multiple);
    } }, dependencies: [i1.NgIf, i2.TkIcon, i3.TkStandaloneCheckbox, i4.NgControlStatus, i4.NgModel], encapsulation: 2, changeDetection: 0 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkOption, [{
        type: Component,
        args: [{ selector: 'tk-option', changeDetection: ChangeDetectionStrategy.OnPush, encapsulation: ViewEncapsulation.None, host: {
                    '[class.tk-option]': 'true',
                    '[class.tk-option--selected]': '!multiple && selected',
                    '[class.tk-option--multiple]': 'multiple',
                    'tabindex': '0',
                }, template: "<tk-checkbox\n\t*ngIf=\"multiple\"\n\t[ngModel]=\"selected\"\n\t[disableClick]=\"true\"\n></tk-checkbox>\n\n<div class=\"tk-option__content\" #content>\n\t<ng-content></ng-content>\n</div>\n\n<tk-icon\n\t*ngIf=\"!multiple\"\n\ticon=\"tkCheck\"\n\tclass=\"tk-option__check-icon\"\n\t[class.tk-option__check-icon--visible]=\"selected\"\n></tk-icon>\n" }]
    }], function () { return [{ type: undefined, decorators: [{
                type: Optional
            }, {
                type: Inject,
                args: [OptionParentToken]
            }] }, { type: i0.ChangeDetectorRef }, { type: i0.ElementRef }]; }, { selected: [{
            type: Input
        }], value: [{
            type: Input
        }], disabled: [{
            type: Input
        }, {
            type: HostListener,
            args: ['class.tk-option--disabled']
        }], multiple: [{
            type: Input
        }], itemClick: [{
            type: Output
        }], content: [{
            type: ViewChild,
            args: ['content']
        }], onClick: [{
            type: HostListener,
            args: ['click']
        }] }); })();

class TkOptionModule {
}
TkOptionModule.ɵfac = function TkOptionModule_Factory(t) { return new (t || TkOptionModule)(); };
TkOptionModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: TkOptionModule });
TkOptionModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule, TkIconModule, TkCheckboxModule, FormsModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkOptionModule, [{
        type: NgModule,
        args: [{
                imports: [CommonModule, TkIconModule, TkCheckboxModule, FormsModule],
                exports: [TkOption],
                declarations: [TkOption],
                providers: [],
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(TkOptionModule, { declarations: [TkOption], imports: [CommonModule, TkIconModule, TkCheckboxModule, FormsModule], exports: [TkOption] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { OptionParentToken, TkOption, TkOptionModule };

