import { ChangeDetectorRef, Component, DestroyRef, HostBinding, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DialogRef } from '@angular/cdk/dialog';
import { CookieService } from 'ngx-cookie-service';
import { DialogCloseDirective } from '@directives/src/dialog-close.directive';
import { IconComponent } from '@ui/icon/icon.component';
import { TkButtonModule } from '@technokratos/components-angular/button';
import { CookieThemeService } from '@technokratos-public/services/cookie-theme.service';
import { HeaderTheme } from '@models/enums/header-theme.enum';
import { ButtonTheme } from '@models/button-theme';

@Component({
	standalone: true,
	selector: 'tk-cookies-modal',
	templateUrl: './cookies-modal.component.html',
	styleUrls: ['./cookies-modal.component.sass'],
	imports: [IconComponent, TkButtonModule, DialogCloseDirective, RouterLink],
})
export class CookiesModalComponent {
	theme: HeaderTheme = HeaderTheme.DARK;

	private dialogRef = inject(DialogRef<CookiesModalComponent>);
	private cookiesService = inject(CookieService);

	constructor() {
		const destroy$ = inject(DestroyRef);
		const cdr = inject(ChangeDetectorRef);
		const themeService = inject(CookieThemeService);

		themeService.theme.pipe(takeUntilDestroyed(destroy$)).subscribe(theme => {
			this.theme = theme;
			cdr.markForCheck();
		});
	}

	@HostBinding('class.light')
	get getIsLight() {
		return this.theme === HeaderTheme.LIGHT;
	}

	get getButtonTheme(): ButtonTheme {
		return this.getIsLight ? 'secondary' : 'ghost';
	}

	acceptCookies(): void {
		this.cookiesService.set('cookiesAgreement', 'yes');
		this.dialogRef.close();
	}
}
