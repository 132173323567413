import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
	standalone: true,
	name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
	transform(value: string) {
		return new Date(DateTime.fromFormat(value, 'dd.MM.yyyy').toISO());
	}
}
