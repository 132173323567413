import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { RouterModule } from '@angular/router';
import { ModalContainerComponent } from '@components/src/modals/modal-container/modal-container.component';
import { ContainerComponent } from '@ui/container/container.component';
import { CheckboxFormControlComponent } from '@ui/custom-form-control/checkbox-form-control.component';
import { LoaderComponent } from '@ui/loader/loader.component';
import { MainComponent } from '@ui/main/main.component';
import { SidebarComponent } from '@ui/sidebar/sidebar.component';
import { TagComponent } from '@ui/tag/tag.component';
import { TkFormFieldModule } from '@technokratos/components-angular/form-field';
import { ButtonComponent, TkButtonModule } from '@technokratos/components-angular/button';
import { TkInputModule } from '@technokratos/components-angular/input';
import { TkRadioModule } from '@technokratos/components-angular/radio';
import { TkCheckboxModule } from '@technokratos/components-angular/checkbox';
import { TkSelectModule } from '@technokratos/components-angular/select';
import { DirectivesModule } from '@directives/directives.module';
import { PipesModule } from '@pipes/pipes.module';
import { ToolsCarouselComponent } from '@ui/tools-carousel/tools-carousel.component';
import { ArticleContentComponent } from '@components/src/shared/article-content/article-content.component';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { DialogModule } from '@angular/cdk/dialog';
import { ChipComponent } from '@ui/chip/chip.component';
import { IconComponent } from '@ui/icon/icon.component';
import { TagsControlComponent } from '@ui/tags-control/tags-control.component';

@NgModule({
	imports: [
		TkFormFieldModule,
		TkButtonModule,
		TkInputModule,
		TkRadioModule,
		TkCheckboxModule,
		TkSelectModule,
		CdkTableModule,
		RouterModule,
		DirectivesModule,
		PipesModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		DialogModule,
		HighlightModule,
		SidebarComponent,
		ToolsCarouselComponent,
		IconComponent,
		ContainerComponent,
		LoaderComponent,
		MainComponent,
		ChipComponent,
		ArticleContentComponent,
		TagsControlComponent,
	],
	declarations: [ModalContainerComponent, CheckboxFormControlComponent, TagComponent],
	exports: [
		IconComponent,
		TkFormFieldModule,
		TkInputModule,
		TkRadioModule,
		TkCheckboxModule,
		TkSelectModule,
		CdkTableModule,
		RouterModule,
		DirectivesModule,
		PipesModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		DialogModule,
		ModalContainerComponent,
		ButtonComponent,
		ContainerComponent,
		CheckboxFormControlComponent,
		LoaderComponent,
		MainComponent,
		SidebarComponent,
		TagComponent,
		ToolsCarouselComponent,
		ArticleContentComponent,
		ChipComponent,
		TagsControlComponent,
	],
	providers: [
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: {
				coreLibraryLoader: () => import('highlight.js/lib/core'),
				languages: {
					typescript: () => import('highlight.js/lib/languages/typescript'),
					css: () => import('highlight.js/lib/languages/css'),
					xml: () => import('highlight.js/lib/languages/xml'),
					json: () => import('highlight.js/lib/languages/json'),
					python: () => import('highlight.js/lib/languages/python'),
					java: () => import('highlight.js/lib/languages/java'),
					sql: () => import('highlight.js/lib/languages/sql'),
				},
			},
		},
	],
})
export class ComponentsModule {}
