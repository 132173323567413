import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { ConfirmModalComponent } from '@technokratos-admin/components/modals/confirm-modal/confirm-modal.component';
import { AdminModalContainerComponent } from '@technokratos-admin/components/modals/admin-modal-container/admin-modal-container.component';
import { DialogService } from '@services/dialog.service';
import { Dialog } from '@angular/cdk/dialog';
import { takeUntil } from 'rxjs';
import { DestroyService } from '@services/destroy.service';

@Component({
	selector: 'tk-admin-menu',
	templateUrl: './admin-menu.component.html',
	styleUrls: ['./admin-menu.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminMenuComponent {
	constructor(
		private authService: AuthService,
		private router: Router,
		private dialogService: DialogService,
		private dialog: Dialog,
		private destroy$: DestroyService,
	) {}

	logout() {
		return this.dialogService
			.openDialog(ConfirmModalComponent, {
				width: '800px',
				container: AdminModalContainerComponent,
				data: {
					title: 'Выйти из системы?',
					btn: 'Выйти',
				},
			})
			.componentInstance?.onSubmit.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.authService.logout();
				this.dialog.closeAll();
				this.router.navigate(['/login']);
			});
	}
}
