import { inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

interface Tags {
	title: string;
	description: string;
	url: string;
	site: string;
	twitterCard: string;
	type: string;
	image?: string;
	publishedTime?: string;
}

@Injectable({ providedIn: 'root' })
export class MetaTagService {
	private meta = inject(Meta);

	addTags(tags: Tags): void {
		this.meta.updateTag({ property: 'og:description', content: tags.description });
		this.meta.updateTag({ property: 'og:title', content: tags.title });
		this.meta.updateTag({ property: 'og:url', content: tags.url });
		this.meta.updateTag({ property: 'og:site_name', content: tags.site });
		this.meta.updateTag({ property: 'og:type', content: tags.type });
		this.meta.updateTag({ name: 'twitter:card', content: tags.twitterCard });
		this.meta.updateTag({ name: 'twitter:site', content: tags.site });
		this.meta.updateTag({ name: 'twitter:title', content: tags.title });
		this.meta.updateTag({ name: 'twitter:description', content: tags.description });
		this.meta.updateTag({ name: 'og:locale', content: 'ru_RU' });

		tags.publishedTime &&
			this.meta.updateTag({
				name: 'article:published_time',
				property: 'article:published_time',
				content: tags.publishedTime,
			});
		tags.image && this.meta.updateTag({ property: 'og:image', content: tags.image });
	}
}
