<button
	*ngFor="let option of options"
	(click)="handleOnChange(option.value)"
	[ngClass]="{
		'tag': true,
		'tag__techno': type === 'techno',
		'tag__agona': type === 'agona',
		'tag--active': isActive(option.value),
		'tag--error': hasError,
	}"
	type="button"
>
	{{ option.label }}
</button>
