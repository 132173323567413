import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ArticlesService } from '@services/articles.service';
import { Router } from '@angular/router';
import { StatusEnum } from '@models/enums/status.enum';
import { AdminModalContainerComponent } from '@technokratos-admin/components/modals/admin-modal-container/admin-modal-container.component';
import { Dialog } from '@angular/cdk/dialog';
import { ConfirmModalComponent } from '@technokratos-admin/components/modals/confirm-modal/confirm-modal.component';
import { LoadingService } from '@services/loading.service';
import { BehaviorSubject, switchMap, takeUntil, tap } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Article } from '@models/article';
import { DestroyService } from '@services/destroy.service';
import { Categories } from '@models/categories';
import { DialogService } from '@services/dialog.service';
import { ToastService } from '@services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'tk-articles',
	templateUrl: './articles.page.html',
	styleUrls: ['/apps/technokratos-admin/src/styles/common.sass', './articles.page.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlesPage implements OnInit {
	articlesList$ = new BehaviorSubject<Article[]>([]);
	categories = Categories;
	isActiveTab = true;
	columns = ['status', 'category', 'title', 'modified_at', 'published_at'];

	constructor(
		private articlesService: ArticlesService,
		private router: Router,
		private dialogService: DialogService,
		private toast: ToastService,
		public loading$: LoadingService,
		private destroy$: DestroyService,
		private dialog: Dialog,
	) {}

	ngOnInit() {
		this.refresh();
	}

	refresh() {
		this.articlesService
			.getArticles()
			.pipe(
				tap(() => this.loading$.next(true)),
				takeUntil(this.destroy$),
			)
			.subscribe({
				next: res => {
					this.articlesList$.next(
						res.filter(
							item => item.status === (this.isActiveTab ? StatusEnum.PUBLISHED : StatusEnum.DRAFT),
						),
					);
					this.loading$.next(false);
				},
				error: err => this.toast.error(err),
			});
	}

	changeTab(isActive: boolean) {
		this.loading$.next(true);
		if (this.isActiveTab === isActive) {
			return;
		}
		this.isActiveTab = isActive;
		this.refresh();
	}

	openArticle(id: number) {
		this.router.navigate(['articles', id]);
	}

	publishArticle(e: Event, id: number, status: string) {
		e.stopPropagation();

		this.dialogService
			.openDialog(ConfirmModalComponent, {
				width: '800px',
				container: AdminModalContainerComponent,
				data: {
					title:
						status === 'DRAFT'
							? 'Вы действительно хотите опубликовать данную статью?'
							: 'Вы действительно хотите сделать черновиком данную статью?',
					btn: status === 'DRAFT' ? 'Опубликовать' : 'Перевести в черновик',
				},
			})
			.componentInstance?.onSubmit.pipe(
				takeUntil(this.destroy$),
				switchMap(() => {
					this.loading$.next(true);
					return this.articlesService.updateArticle(id, {
						status: status === 'DRAFT' ? 'PUBLISHED' : 'DRAFT',
					});
				}),
			)
			.subscribe({
				next: () => {
					this.toast.success(status === 'DRAFT' ? 'Статья опубликована' : 'Черновик создан');
					this.refresh();
					this.dialog.closeAll();
				},
				error: (err: HttpErrorResponse) => this.toast.error(err),
			});
	}

	deleteArticle(e: Event, id: number) {
		e.stopPropagation();

		this.dialogService
			.openDialog(ConfirmModalComponent, {
				width: '800px',
				container: AdminModalContainerComponent,
				data: {
					title: 'Вы действительно хотите удалить данную статью?',
					btn: 'Удалить',
				},
			})
			.componentInstance?.onSubmit.pipe(
				takeUntil(this.destroy$),
				switchMap(() => {
					this.loading$.next(true);
					return this.articlesService.deleteArticle(id);
				}),
			)
			.subscribe({
				next: () => {
					this.toast.success('Статья успешно удалена');
					this.refresh();
					this.dialog.closeAll();
				},
				error: (err: HttpErrorResponse) => this.toast.error(err),
			});
	}

	changeOrder(event: CdkDragDrop<string[]>) {
		const items = this.articlesList$.getValue();
		const articleId = items[event.previousIndex].id;
		const articleNewOrder = items[event.currentIndex].order;

		this.loading$.next(true);
		this.articlesService
			.updateArticle(articleId!, {
				order: articleNewOrder,
			})
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => this.refresh(),
				error: err => this.toast.error(err),
			});
	}
}
