import { Routes } from '@angular/router';
import { LoginComponent } from '@pages-admin/login/login.page';
import { LoginErrorComponent } from '@pages-admin/login-error/login-error.component';
import { ArticlesPage } from '@pages-admin/articles/articles.page';
import { AuthGuard } from '@technokratos-admin/guards/auth.guard';
import { ArticleEditPage } from '@pages-admin/articles/article-edit/article-edit.page';
import { UnsavedChangesGuard } from '@technokratos-admin/guards/unsaved-changes.guard';
import { MailPage } from '@pages-admin/mail/mail.component';
import { AuthorsPage } from '@pages-admin/authors/authors.page';
import { TagsPage } from '@pages-admin/tags/tags.page';
import { AdminLayoutComponent } from '@technokratos-admin/components/admin-layout/admin-layout.component';
import { EditAuthorPage } from '@pages-admin/authors/edit-author/edit-author.page';
import { EditTagPage } from '@pages-admin/tags/edit-tag/edit-tag.page';

export const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent,
	},
	{
		path: 'login-error',
		component: LoginErrorComponent,
	},
	{
		path: '',
		component: AdminLayoutComponent,
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'articles',
			},
			{
				path: 'articles',
				component: ArticlesPage,
				canActivate: [AuthGuard],
			},
			{
				path: 'articles/new',
				component: ArticleEditPage,
				canActivate: [AuthGuard],
				canDeactivate: [UnsavedChangesGuard],
			},
			{
				path: 'articles/:articleId',
				component: ArticleEditPage,
				canActivate: [AuthGuard],
				canDeactivate: [UnsavedChangesGuard],
			},
			{
				path: 'mail',
				component: MailPage,
				canActivate: [AuthGuard],
			},
			{
				path: 'authors',
				canActivate: [AuthGuard],
				children: [
					{
						path: '',
						pathMatch: 'full',
						component: AuthorsPage,
					},
					{
						path: 'profile',
						component: EditAuthorPage,
					},
				],
			},
			{
				path: 'tags',
				canActivate: [AuthGuard],
				children: [
					{
						path: '',
						pathMatch: 'full',
						component: TagsPage,
					},
					{
						path: 'edit',
						component: EditTagPage,
					},
				],
			},
		],
	},
];
