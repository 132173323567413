export class LocalStorageService {
	private storage: { [key: string]: unknown } = {};

	addStorageKey(storageKey: string): void {
		const storageData: string = localStorage.getItem(storageKey) || '';
		this.storage[storageKey] = storageData ? JSON.parse(storageData) : null;
	}

	getStorageValue(storageKey: string) {
		return this.storage[storageKey];
	}

	setStorageValue(storageKey: string, value: unknown) {
		this.storage[storageKey] = value;
	}

	saveAllStorages(): void {
		for (const prop in this.storage) {
			this.hasProperty(prop) && localStorage.setItem(prop, JSON.stringify(this.storage[prop]));
		}
	}

	saveStorageByKey(storageKey: string): void {
		this.hasProperty(storageKey) && localStorage.setItem(storageKey, JSON.stringify(this.storage[storageKey]));
	}

	clearStorage(): void {
		localStorage.clear();
		for (const prop in this.storage) {
			if (this.hasProperty(prop)) {
				this.storage[prop] = null;
			}
		}
	}

	clearStorageByKey(storageKey: string): void {
		if (this.hasProperty(storageKey)) {
			localStorage.removeItem(storageKey);
			this.storage[storageKey] = null;
		}
	}

	private hasProperty(key: string) {
		return key in this.storage;
	}
}

export const storageService = new LocalStorageService();

export function LocalStorage(autoSave: boolean = true, key?: string): any {
	return (target: object, propName: string) => {
		const propNameId = key ? key : propName;

		storageService.addStorageKey(propNameId);

		function getValue() {
			return storageService.getStorageValue(propNameId);
		}

		function setValueAuto(val: unknown) {
			storageService.setStorageValue(propNameId, val);
			storageService.saveStorageByKey(propNameId);
		}

		function setValue(val: unknown) {
			storageService.setStorageValue(propNameId, val);
		}

		Object.defineProperty(target, propName, {
			configurable: true,
			enumerable: true,
			get: getValue,
			set: autoSave ? setValueAuto : setValue,
		});
	};
}
