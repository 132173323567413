import { HttpContextToken, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { environment } from '@environments/environment';

export const StartsWithHttp = new HttpContextToken<boolean>(() => false);

export const UrlInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
	const tempUrl =
		request.url.startsWith('https') || request.url.startsWith('/') || request.context.get(StartsWithHttp)
			? request.url
			: [environment.apiUrl, request.url].join('/');

	const url = encodeURI(tempUrl);
	return next(
		request.clone({
			url,
			headers: request.headers.set('accept', 'application/json'),
		}),
	);
};
