import { ChangeDetectionStrategy, Component, inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ArticlesService } from '@services/articles.service';
import { ActivatedRoute } from '@angular/router';
import { Article } from '@models/article';
import { BehaviorSubject } from 'rxjs';
import { LoadingService } from '@services/loading.service';
import { DateTime } from 'luxon';
import { Categories } from '@models/categories';
import { MetaTagService } from '@services/meta-tag.service';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { OutputData } from '@editorjs/editorjs';
import { OutputBlockData } from '@editorjs/editorjs/types/data-formats/output-data';
import { Author } from '@models/author';
import { ToastService } from '@services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IconComponent } from '@ui/icon/icon.component';
import { HighlightModule } from 'ngx-highlightjs';
import { SafePipe } from '@pipes/src/safe-url.pipe';
import { LoaderComponent } from '@ui/loader/loader.component';
import { GoBackComponent } from '@ui/go-back/go-back.component';
import { AvatarComponent } from '@ui/avatar/avatar.component';

enum ArticleBlockTypeEnum {
	HEADER = 'header',
	PARAGRAPH = 'paragraph',
	LIST = 'list',
	DELIMITER = 'delimiter',
	CODE = 'code',
	IMAGE = 'image',
	EMBED = 'embed',
	INCUT = 'incut',
	NUMBERS = 'numbers',
	QUOTE = 'quote',
}

interface ArticleBlock extends Omit<OutputBlockData, 'data'> {
	type: ArticleBlockTypeEnum;
	data: {
		level: number;
		text: string;
		style: string;
		items: string[];
		code: string;
		authorName: string;
		authorPosition: string;
		authorAvatarPath: string;
		file: {
			url: string;
		};
		caption: string;
		withBorder: boolean;
		stretched: boolean;
		withBackground: boolean;
		embed: string;
		width: number;
		height: number;
	};
}

interface NumberBlock {
	head: string;
	text: string;
}

interface ArticleBlockNumbers extends Omit<OutputBlockData, 'data'> {
	data: NumberBlock[];
}

interface ArticleContent extends Omit<OutputData, 'blocks'> {
	blocks: ArticleBlock[];
}

@Component({
	standalone: true,
	selector: 'tk-article-content',
	templateUrl: './article-content.component.html',
	styleUrls: ['./article-content.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [LoadingService],
	imports: [
		CommonModule,
		IconComponent,
		HighlightModule,
		SafePipe,
		LoaderComponent,
		GoBackComponent,
		AvatarComponent,
	],
})
export class ArticleContentComponent implements OnInit, OnDestroy {
	@Input() previewData?: Article;
	//TODO: убрать потом
	@Input() previewContent?: any;

	articleContent$ = new BehaviorSubject<Article | null>(null);

	articleContent!: ArticleContent;
	formattedDate: Date | null = null;
	categories = Categories;
	BlockType = ArticleBlockTypeEnum;

	public loading$ = inject(LoadingService);
	private route = inject(ActivatedRoute);
	private articlesService = inject(ArticlesService);
	private toast = inject(ToastService);
	private metaTagService = inject(MetaTagService);
	private document = inject(DOCUMENT);
	private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

	ngOnInit(): void {
		if (this.previewData) {
			this.articleContent$.next(this.previewData as Article);
			this.articleContent = JSON.parse(this.previewContent);
			this.formattedDate = new Date(this.previewData.published_at);
			this.loading$.next(false);
			return;
		}

		this.articlesService.getPublicArticle(this.route.snapshot.params['id']).subscribe({
			next: res => {
				this.metaTagService.addTags({
					title: res.title,
					description: res.subtitle,
					url: `${this.document.location.href}`,
					site: 'Технократия',
					type: 'article',
					image: res.cover,
					twitterCard: 'summary_large_image',
					publishedTime: res.published_at,
				});
				this.articleContent$.next(res);
				this.articleContent = JSON.parse(res.content);
				this.formattedDate = new Date(DateTime.fromFormat(res.published_at, 'dd.MM.yyyy').toISO().toString());
				this.loading$.next(false);
			},
			error: (err: HttpErrorResponse) => {
				if (err.status === 404) {
					this.loading$.next(false);
				}

				if (this.isBrowser) {
					this.toast.error(err.status === 404 ? 'Статья не найдена' : err);
				}
			},
		});
	}

	blockAsNumbers(block: ArticleBlock) {
		return block as unknown as ArticleBlockNumbers;
	}

	trackByNumberBlock(_: number, block: NumberBlock) {
		return block;
	}

	trackByBlock(_: number, item: ArticleBlock) {
		return item.id ?? item;
	}

	trackByTag(_: number, item: string) {
		return item;
	}

	trackByAuthor(_: number, item: Author) {
		return item.id ?? item;
	}

	ngOnDestroy() {
		this.articleContent$.complete();
	}
}
