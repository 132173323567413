<section class="header">
	<h3>Статьи</h3>
	<tk-button routerLink="/articles/new">Создать статью</tk-button>
</section>

<section class="tab">
	<button (click)="changeTab(true)" [class.active]="isActiveTab" class="tab__item">Активные</button>
	<button (click)="changeTab(false)" [class.active]="!isActiveTab" class="tab__item">Черновики</button>
</section>

<cdk-table
	(cdkDropListDropped)="changeOrder($event)"
	*ngIf="!(loading$ | async); else loader"
	[dataSource]="articlesList$"
	cdkDropList
>
	<cdk-header-row *cdkHeaderRowDef="columns"></cdk-header-row>

	<ng-container cdkColumnDef="status">
		<cdk-header-cell *cdkHeaderCellDef> Статус </cdk-header-cell>

		<cdk-cell *cdkCellDef="let row"> {{ row.status === 'PUBLISHED' ? 'Опубликована' : 'Черновик' }} </cdk-cell>
	</ng-container>

	<ng-container cdkColumnDef="category">
		<cdk-header-cell *cdkHeaderCellDef> Рубрика </cdk-header-cell>

		<cdk-cell *cdkCellDef="let row"> {{ categories[row.category].name }} </cdk-cell>
	</ng-container>

	<ng-container cdkColumnDef="title">
		<cdk-header-cell *cdkHeaderCellDef> Статья </cdk-header-cell>

		<cdk-cell *cdkCellDef="let row"> {{ row.title }} </cdk-cell>
	</ng-container>

	<ng-container cdkColumnDef="modified_at">
		<cdk-header-cell *cdkHeaderCellDef> Дата изменения </cdk-header-cell>

		<cdk-cell *cdkCellDef="let row"> {{ row.modified_at }} </cdk-cell>
	</ng-container>

	<ng-container cdkColumnDef="published_at">
		<cdk-header-cell *cdkHeaderCellDef class="cell--right"> Дата публикации </cdk-header-cell>

		<cdk-cell *cdkCellDef="let row" class="cell--right">
			<div class="hovered_row--hide">{{ row.published_at ?? '-' }}</div>
			<div class="hovered_row--show">
				<button (click)="publishArticle($event, row.id, row.status)" class="control control--edit">
					<tk-icon icon="doc_used" />
				</button>
				<button (click)="deleteArticle($event, row.id)" class="control control--delete">
					<tk-icon icon="trash" />
				</button>
			</div>
		</cdk-cell>
	</ng-container>

	<cdk-row (click)="openArticle(+row.id)" *cdkRowDef="let row; columns: columns" cdkDrag class="pointer"></cdk-row>
</cdk-table>

<ng-template #loader>
	<tk-loader width="FULL"></tk-loader>
</ng-template>
