import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TypeEnum } from '@models/enums/type.enum';
import { InjectorInstance } from '@technokratos-admin/app.module';
import { Image } from '@models/image';

@Injectable({
	providedIn: 'root',
})
export class ImageService {
	constructor(private http: HttpClient) {}

	static uploadImage(file: File, type: TypeEnum) {
		const httpClient = InjectorInstance.get<HttpClient>(HttpClient);

		const fd = new FormData();
		fd.append('image', file);
		fd.append('type_', type);

		return httpClient.post('admin/blog/images/', fd);
	}

	uploadImage(file: File, type: TypeEnum): Observable<Image> {
		const fd = new FormData();
		fd.append('image', file);
		fd.append('type_', type);

		return this.http.post<{ name: string; url: string }>('admin/blog/images/', fd);
	}
}
