import { Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { isNotEmpty } from '@utils/is-not-empty';

const DEFAULT_AVATAR = '/assets/images/default_avatar.webp';

@Component({
	selector: 'tk-avatar',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './avatar.component.html',
	styleUrl: './avatar.component.sass',
})
export class AvatarComponent {
	private _src?: string;

	get src() {
		return isNotEmpty(this._src) ? this._src : DEFAULT_AVATAR;
	}

	@Input()
	set src(val) {
		this._src = val;
	}

	@Input()
	@HostBinding('style.--tk-size')
	size = '40px';
}
