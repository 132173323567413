import { inject, Injectable } from '@angular/core';
import { DestroyService } from '@services/destroy.service';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { messageError, titleError } from '@utils/errorsFromBack';

@Injectable({
	providedIn: 'root',
	deps: [DestroyService],
})
export class ToastService {
	toastr = inject(ToastrService);

	error(message: string | HttpErrorResponse, title?: string, config?: Partial<IndividualConfig>) {
		return this.toastr.error(
			messageError(message),
			titleError(typeof message === 'string' ? title : message),
			config,
		);
	}

	success(message: string, title?: string, config?: Partial<IndividualConfig>) {
		return this.toastr.success(message, title, config);
	}

	info(message: string, title?: string, config?: Partial<IndividualConfig>) {
		return this.toastr.info(message, title, config);
	}
}
