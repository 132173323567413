import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppModule } from '@technokratos-admin/app.module';

@Component({
	standalone: true,
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.sass'],
	imports: [RouterOutlet, AppModule],
})
export class AppComponent {}
