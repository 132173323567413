import { ChangeDetectionStrategy, Component, ContentChild, HostBinding, Input, TemplateRef } from '@angular/core';
import { BooleanInput, coerceBooleanProperty, coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import { NgForOf, NgTemplateOutlet } from '@angular/common';

@Component({
	standalone: true,
	selector: 'tk-tools-carousel',
	templateUrl: './tools-carousel.component.html',
	styleUrls: ['./tools-carousel.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgForOf, NgTemplateOutlet],
})
export class ToolsCarouselComponent {
	@HostBinding('class') @Input() way: 'horizontal' | 'diagonal-left' = 'horizontal';
	@ContentChild('carouselContent', { descendants: true }) carouselContent?: TemplateRef<unknown>;

	private _amountOfBlocks = 2;

	@Input()
	get amountOfBlocks() {
		return this._amountOfBlocks;
	}

	set amountOfBlocks(value: NumberInput) {
		this._amountOfBlocks = coerceNumberProperty(value);
	}

	private _animationLength = '48s';

	@Input()
	@HostBinding('style.--animation-length')
	get animationLength() {
		return this._animationLength;
	}

	set animationLength(value: NumberInput) {
		this._animationLength = `${coerceNumberProperty(value)}s`;
	}

	private _itemsGap = '20px';

	@Input()
	@HostBinding('style.--items-gap')
	get itemsGap() {
		return this._itemsGap;
	}

	set itemsGap(value: NumberInput) {
		this._itemsGap = `${coerceNumberProperty(value)}px`;
	}

	private _withHover = false;

	@Input()
	@HostBinding('class.with-hover')
	get withHover(): boolean {
		return this._withHover;
	}

	set withHover(value: BooleanInput) {
		this._withHover = coerceBooleanProperty(value);
	}
}
