import { NgModule } from '@angular/core';
import { SafePipe } from './src/safe-url.pipe';
import { NumberEndingPipe } from './src/number-ending.pipe';
import { DateFormatPipe } from './src/date-format.pipe';

@NgModule({
	imports: [SafePipe, DateFormatPipe, NumberEndingPipe],
	exports: [SafePipe, NumberEndingPipe, DateFormatPipe],
})
export class PipesModule {}
