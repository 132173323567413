import * as i0 from '@angular/core';
import { Directive, Input } from '@angular/core';
import * as i1 from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

class AbstractTkControl {
    constructor(ngControl, cdr) {
        this.ngControl = ngControl;
        this.cdr = cdr;
        /**
         * disabled и readonly делаем через геттеры, чтобы работал такой синтаксис
         * <input disabled/>
         * @private
         */
        this._disabled = false;
        this._readonly = false;
        this.onTouchedCallback = () => { };
        this.onChangeCallback = () => { };
    }
    get value() {
        return this._value;
    }
    set value(value) {
        this._value = value;
    }
    get disabled() {
        return this._disabled || this.ngControl?.disabled;
    }
    set disabled(value) {
        this._disabled = coerceBooleanProperty(value);
    }
    get readonly() {
        return this._readonly;
    }
    set readonly(value) {
        this._readonly = coerceBooleanProperty(value);
    }
    writeValue(value) {
        this.value = value;
    }
    get invalid() {
        return this.ngControl?.invalid;
    }
    get hasValue() {
        return Boolean(this.value);
    }
    get labelFloat() {
        return (this.focused && !this.readonly) || Boolean(this.value);
    }
    get touched() {
        return this.ngControl?.touched || false;
    }
    containerClick() { }
    registerOnChange(fn) {
        this.onChangeCallback = fn;
    }
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    setDisabledState(isDisabled) {
        this.disabled = isDisabled;
    }
}
AbstractTkControl.ɵfac = function AbstractTkControl_Factory(t) { return new (t || AbstractTkControl)(i0.ɵɵdirectiveInject(i1.NgControl), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef)); };
AbstractTkControl.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: AbstractTkControl, inputs: { disabled: "disabled", readonly: "readonly" } });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTkControl, [{
        type: Directive
    }], function () { return [{ type: i1.NgControl }, { type: i0.ChangeDetectorRef }]; }, { disabled: [{
            type: Input
        }], readonly: [{
            type: Input
        }] }); })();

const noop = () => { };

/**
 * Generated bundle index. Do not edit.
 */

export { AbstractTkControl, noop };

