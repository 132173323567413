import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorsService } from '@services/authors.service';
import { CreateAuthorRequest } from '@models/create-author-request';
import { ToastService } from '@services/toast.service';

type CreateAuthorForm = {
	name: FormControl<string>;
	position: FormControl<string>;
	avatar: FormControl<string | null>;
};

@Component({
	selector: 'tk-edit-author',
	templateUrl: './edit-author.page.html',
	styleUrls: ['/apps/technokratos-admin/src/styles/common.sass', './edit-author.page.sass'],
})
export class EditAuthorPage implements OnInit {
	readonly authorService = inject(AuthorsService);
	readonly router = inject(Router);
	readonly route = inject(ActivatedRoute);
	readonly toast = inject(ToastService);

	form = new FormGroup<CreateAuthorForm>({
		name: new FormControl('', {
			nonNullable: true,
			validators: [Validators.required],
		}),
		position: new FormControl('', {
			nonNullable: true,
			validators: [Validators.required],
		}),
		avatar: new FormControl(null),
	});

	authorId = inject(ActivatedRoute).snapshot.queryParams['id'];
	isSubmitted = false;

	ngOnInit(): void {
		if (this.authorId) {
			this.authorService.getAuthor(this.authorId).subscribe({
				next: data => this.form.patchValue(data),
			});
		}
	}

	submit() {
		this.isSubmitted = true;

		if (this.form.invalid) {
			return;
		}

		if (this.authorId) {
			return this.authorService.updateAuthor(this.authorId, this.form.value).subscribe({
				next: () => {
					this.toast.success('Автор сохранен');
					this.goBack();
				},
			});
		}

		return this.authorService.createAuthor(this.form.value as CreateAuthorRequest).subscribe({
			next: () => {
				this.toast.success('Автор создан');
				this.goBack();
			},
		});
	}

	goBack() {
		this.router.navigate(['authors']);
	}
}
