import { ChangeDetectorRef, Component, forwardRef, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'tk-tags-control',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './tags-control.component.html',
	styleUrl: './tags-control.component.sass',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => TagsControlComponent),
		},
	],
})
export class TagsControlComponent<T> implements ControlValueAccessor {
	@Input() options: {
		value: T;
		label: string;
	}[] = [];

	@Input() isMultiple = true;
	@Input() hasError = false;
	@Input() type: 'agona' | 'techno' = 'techno';

	value: T | T[] = [];

	cdr = inject(ChangeDetectorRef);

	isActive(option: T) {
		return Array.isArray(this.value) ? this.value.find(item => item === option) : this.value === option;
	}

	handleOnChange(selectedValue: T) {
		if (this.isMultiple && Array.isArray(this.value)) {
			const index = this.value.indexOf(selectedValue);
			this.value =
				index === -1 ? [...this.value, selectedValue] : this.value.filter(item => item !== selectedValue);
		} else {
			this.value = selectedValue;
		}

		this.onTouched();
		this.onChange(this.value);
		this.cdr.markForCheck();
	}

	onChange = (value: T | T[]) => {};
	onTouched = () => {};

	writeValue(value: T | T[]): void {
		this.value = value;
	}

	registerOnChange(fn: (value: T | T[]) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
}
