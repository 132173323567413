import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, ElementRef, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, of } from 'rxjs';
import * as i1 from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { shareReplay, map, tap } from 'rxjs/operators';

const WIDTH_REGEX = /viewBox=['"]0 0 (\d+) \d+['"]/;
const HEIGHT_REGEX = /viewBox=['"]0 0 \d+ (\d+)['"]/;
const SymbolRegex = (symbol) => new RegExp(`<symbol[^>]*id="${symbol}"[\\s\\S]*?<\/symbol>`, 'g');
const ViewboxRegex = /viewBox=['"](.*?)['"]/;
function getSize(source) {
    const matchedWidth = source.match(WIDTH_REGEX);
    const matchedHeight = source.match(HEIGHT_REGEX);
    if (!matchedWidth || !matchedWidth) {
        return [null, null];
    }
    return [+matchedWidth[1], +matchedHeight[1]];
}
function setSize(source, width, height) {
    return source
        .replace(WIDTH_REGEX, `width="${width}"`)
        .replace(HEIGHT_REGEX, `height="${height}"`);
}
function symbolToSvg(res, mappedSrc) {
    const use = mappedSrc.split('#')[1];
    const matched = res.match(SymbolRegex(use));
    if (!matched?.length) {
        return null;
    }
    const matchedViewbox = matched[0].match(ViewboxRegex);
    if (!(matchedViewbox && matchedViewbox[1])) {
        return null;
    }
    return `<svg
			version='1.1'
			xmlns='http://www.w3.org/2000/svg'
			focusable='false'
			viewBox='${matchedViewbox[1]}'
		>
			<use xlink:href='${mappedSrc}'></use>
		</svg>`;
}
const DEFAULT_ICONS_PATH = name => name.includes('.svg#') ? name : `assets/sprite.svg#${name}`;
const DefaultIcons = {
    tkDropdownArrow: '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M19.5303 8.46967c.2929.29289.2929.76777 0 1.06066l-7 6.99997c-.1406.1407-.3314.2197-.5303.2197-.1989 0-.3897-.079-.5303-.2197L4.46967 9.53033c-.29289-.29289-.29289-.76777 0-1.06066s.76777-.29289 1.06066 0L12 14.9393l6.4697-6.46963c.2929-.29289.7677-.29289 1.0606 0z" /></svg>',
    tkCalendarArrow: '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path  d="M13.5302 4.96979C13.8231 5.26269 13.8231 5.73756 13.5302 6.03045L8.53021 11.0305C8.23731 11.3233 7.76244 11.3233 7.46955 11.0305L2.46955 6.03045C2.17665 5.73756 2.17665 5.26268 2.46955 4.96979C2.76244 4.6769 3.23732 4.6769 3.53021 4.96979L7.99988 9.43946L12.4695 4.96979C12.7624 4.6769 13.2373 4.6769 13.5302 4.96979Z" /></svg>',
    tkCheck: '<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M16.03 5.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06 0l-3-3a.75.75 0 111.06-1.06L8 12.44l6.97-6.97a.75.75 0 011.06 0z"/></svg>',
    tkCheckIndeterminate: '<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4 10C4 9.44772 4.44772 9 5 9H15C15.5523 9 16 9.44772 16 10C16 10.5523 15.5523 11 15 11H5C4.44772 11 4 10.5523 4 10Z"/></svg>',
};

const TK_ICONS_PATH = new InjectionToken('A handler to retrieve USE id for icon by name', {
    factory: () => DEFAULT_ICONS_PATH,
});
const TK_ICONS = new InjectionToken('A key/value dictionary of icon names and src to be defined with TkSvgService', {
    factory: () => DefaultIcons,
});

class TkStaticRequestService {
    constructor() {
        this.cache = new Map();
    }
    request(url) {
        const urlBase = url.split('#')[0];
        const cache = this.cache.get(urlBase);
        if (cache) {
            return cache;
        }
        const observable = new Observable((observer) => {
            const xhr = new XMLHttpRequest();
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    const response = xhr.responseType
                        ? xhr.response
                        : xhr.responseText;
                    if (xhr.status === 200) {
                        observer.next(response);
                        observer.complete();
                    }
                    else {
                        observer.error(response);
                    }
                }
            };
            xhr.open('GET', urlBase);
            xhr.send();
            return () => {
                xhr.abort();
            };
        });
        const piped = observable.pipe(shareReplay(1));
        this.cache.set(urlBase, piped);
        return piped;
    }
}
TkStaticRequestService.ɵfac = function TkStaticRequestService_Factory(t) { return new (t || TkStaticRequestService)(); };
TkStaticRequestService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TkStaticRequestService, factory: TkStaticRequestService.ɵfac, providedIn: 'root' });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkStaticRequestService, [{
        type: Injectable,
        args: [{
                providedIn: 'root',
            }]
    }], null, null); })();

class TkIconService {
    constructor(sanitizer, icons, path, staticRequestService) {
        this.sanitizer = sanitizer;
        this.icons = icons;
        this.path = path;
        this.staticRequestService = staticRequestService;
        this.cache = new Map();
    }
    getIcon(src) {
        const mappedSrc = this.icons[src] || src;
        if (mappedSrc.includes('<svg')) {
            return of(mappedSrc);
        }
        if (mappedSrc.includes('.svg')) {
            return this.staticRequestService.request(mappedSrc);
        }
        return this.staticRequestService.request(this.path(mappedSrc)).pipe(
        // преобразовываем символ в свгшку, работать с ними одинаково
        map(res => symbolToSvg(res, this.path(mappedSrc))));
    }
    getIconCached(src) {
        const cache = this.cache.get(src);
        if (cache) {
            return cache;
        }
        const piped = this.getIcon(src).pipe(shareReplay(1));
        this.cache.set(src, piped);
        return piped;
    }
}
TkIconService.ɵfac = function TkIconService_Factory(t) { return new (t || TkIconService)(i0.ɵɵinject(DomSanitizer), i0.ɵɵinject(TK_ICONS), i0.ɵɵinject(TK_ICONS_PATH), i0.ɵɵinject(TkStaticRequestService)); };
TkIconService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TkIconService, factory: TkIconService.ɵfac, providedIn: 'root' });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkIconService, [{
        type: Injectable,
        args: [{
                providedIn: 'root',
            }]
    }], function () { return [{ type: i1.DomSanitizer, decorators: [{
                type: Inject,
                args: [DomSanitizer]
            }] }, { type: undefined, decorators: [{
                type: Inject,
                args: [TK_ICONS]
            }] }, { type: undefined, decorators: [{
                type: Inject,
                args: [TK_ICONS_PATH]
            }] }, { type: TkStaticRequestService, decorators: [{
                type: Inject,
                args: [TkStaticRequestService]
            }] }]; }, null); })();

class TkIcon {
    constructor(svgService, elementRef, renderer) {
        this.svgService = svgService;
        this.elementRef = elementRef;
        this.renderer = renderer;
    }
    get src() {
        return this._src;
    }
    set src(value) {
        if (this._src === value) {
            return;
        }
        this._src = value;
        this.svgService
            .getIconCached(value)
            .pipe(tap(src => {
            if (!src) {
                throw Error(`Could not load icon with name ${this.src}`);
            }
        }))
            .subscribe(src => {
            this.innerSrc = src;
            this.updateSize();
            this.elementRef.nativeElement.innerHTML = src;
        });
    }
    get size() {
        return this._size;
    }
    set size(value) {
        this._size = value;
        this.updateSize();
    }
    updateSize() {
        if (!this.innerSrc) {
            return;
        }
        const [iconWidth, iconHeight] = getSize(this.innerSrc);
        const width = +this._size || iconWidth || 24;
        const height = +this._size || iconHeight || 24;
        this.renderer.setAttribute(this.elementRef.nativeElement, 'style', `width: ${width}px; height: ${height}px`);
    }
}
TkIcon.ɵfac = function TkIcon_Factory(t) { return new (t || TkIcon)(i0.ɵɵdirectiveInject(TkIconService), i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2)); };
TkIcon.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TkIcon, selectors: [["tk-icon"]], hostVars: 2, hostBindings: function TkIcon_HostBindings(rf, ctx) { if (rf & 2) {
        i0.ɵɵclassProp("tk-icon", true);
    } }, inputs: { src: ["icon", "src"], size: "size" }, decls: 0, vars: 0, template: function TkIcon_Template(rf, ctx) { }, encapsulation: 2, changeDetection: 0 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkIcon, [{
        type: Component,
        args: [{
                selector: 'tk-icon',
                template: '',
                changeDetection: ChangeDetectionStrategy.OnPush,
                host: {
                    '[class.tk-icon]': 'true',
                },
                encapsulation: ViewEncapsulation.None,
            }]
    }], function () { return [{ type: TkIconService, decorators: [{
                type: Inject,
                args: [TkIconService]
            }] }, { type: i0.ElementRef, decorators: [{
                type: Inject,
                args: [ElementRef]
            }] }, { type: i0.Renderer2 }]; }, { src: [{
            type: Input,
            args: ['icon']
        }], size: [{
            type: Input
        }] }); })();

class TkIconModule {
}
TkIconModule.ɵfac = function TkIconModule_Factory(t) { return new (t || TkIconModule)(); };
TkIconModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: TkIconModule });
TkIconModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkIconModule, [{
        type: NgModule,
        args: [{
                declarations: [TkIcon],
                exports: [TkIcon],
                imports: [CommonModule],
                providers: [],
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(TkIconModule, { declarations: [TkIcon], imports: [CommonModule], exports: [TkIcon] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DEFAULT_ICONS_PATH, DefaultIcons, TK_ICONS, TK_ICONS_PATH, TkIcon, TkIconModule, getSize, setSize, symbolToSvg };

