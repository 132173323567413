import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Dialog } from '@angular/cdk/dialog';
import { switchMap, takeUntil, tap } from 'rxjs';
import { AuthorsService } from '@services/authors.service';
import { LoadingService } from '@services/loading.service';
import { DestroyService } from '@services/destroy.service';
import { DialogService } from '@services/dialog.service';
import { ToastService } from '@services/toast.service';
import { Author } from '@models/author';
import { ConfirmModalComponent } from '@technokratos-admin/components/modals/confirm-modal/confirm-modal.component';
import { AdminModalContainerComponent } from '@technokratos-admin/components/modals/admin-modal-container/admin-modal-container.component';

@Component({
	selector: 'tk-authors',
	templateUrl: './authors.page.html',
	styleUrls: ['/apps/technokratos-admin/src/styles/common.sass', './authors.page.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorsPage implements OnInit {
	readonly router = inject(Router);
	readonly route = inject(ActivatedRoute);
	readonly authorsService = inject(AuthorsService);
	readonly dialogService = inject(DialogService);
	readonly dialog = inject(Dialog);
	readonly toast = inject(ToastService);
	readonly loading$ = inject(LoadingService);
	readonly destroy$ = inject(DestroyService);

	authorList$ = this.authorsService.authorList$;
	columns = ['avatar', 'name', 'position'];

	ngOnInit() {
		this.refresh();
	}

	refresh() {
		this.authorsService
			.getAuthors()
			.pipe(
				tap(() => this.loading$.next(true)),
				takeUntil(this.destroy$),
			)
			.subscribe({
				next: res => {
					this.authorList$.next(res);
					this.loading$.next(false);
				},
				error: err => {
					this.toast.error(err);
				},
			});
	}

	deleteAuthor(e: Event, id: number) {
		e.stopPropagation();

		this.dialogService
			.openDialog(ConfirmModalComponent, {
				width: '800px',
				container: AdminModalContainerComponent,
				data: {
					title: 'Вы действительно хотите удалить автора?',
					btn: 'Удалить',
				},
			})
			.componentInstance?.onSubmit.pipe(
				takeUntil(this.destroy$),
				switchMap(() => {
					this.loading$.next(true);
					return this.authorsService.deleteAuthor(id);
				}),
			)
			.subscribe({
				next: () => {
					this.toast.success('Автор удалён');
					this.refresh();
					this.dialog.closeAll();
				},
				error: (err: HttpErrorResponse) => this.toast.error(err),
			});
	}

	editAuthor(row: Author) {
		this.router.navigate(['profile'], {
			queryParams: { id: row.id },
			relativeTo: this.route,
		});
	}
}
