import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminModalContainerComponent } from '@technokratos-admin/components/modals/admin-modal-container/admin-modal-container.component';
import { UnsavedChangesComponent } from '@models/unsaved-changes-component.interface';
import { UnsavedChangesModalComponent } from '@technokratos-admin/components/modals/unsaved-changes-modal/unsaved-changes-modal.component';
import { DialogService } from '@services/dialog.service';

@Injectable({
	providedIn: 'root',
})
export class UnsavedChangesGuard {
	constructor(private dialogService: DialogService) {}

	canDeactivate(
		component: UnsavedChangesComponent,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return component.canDeactivate()
			? true
			: (this.dialogService.openDialog(UnsavedChangesModalComponent, {
					width: '800px',
					container: AdminModalContainerComponent,
					data: {
						title: 'Внесенные изменения не сохранятся. Покинуть страницу?',
						btn: 'Уйти',
					},
				}).closed as Observable<boolean>);
	}
}
