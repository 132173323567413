import { Component, inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
	selector: 'tk-confirm-modal',
	templateUrl: './unsaved-changes-modal.component.html',
	styleUrls: ['./unsaved-changes-modal.component.sass', '/apps/technokratos-admin/src/styles/common.sass'],
})
export class UnsavedChangesModalComponent {
	readonly dialogRef = inject(DialogRef);
	readonly data: { title: string; btn: string } = inject(DIALOG_DATA);

	submitForm = () => {
		this.dialogRef.close(true);
	};

	closeModal = () => {
		this.dialogRef.close(false);
	};
}
