import { ChangeDetectionStrategy, Component, HostListener, inject } from '@angular/core';
import { IconComponent } from '@ui/icon/icon.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'tk-go-back',
	standalone: true,
	templateUrl: './go-back.component.html',
	styleUrl: './go-back.component.sass',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IconComponent],
})
export class GoBackComponent {
	readonly router = inject(Router);
	readonly route = inject(ActivatedRoute);

	@HostListener('click')
	onClick(): void {
		this.router.navigate(['.'], { relativeTo: this.route.parent });
	}
}
