@if (!(loading$ | async)) {
	<section class="article">
		@if (!previewData) {
			<tk-go-back class="article__controls" />
		}
		<ng-container *ngIf="articleContent$ | async as articleData">
			<h2 class="article__title">{{ articleData.title }}</h2>

			<section class="article__tags">
				<div *ngFor="let tag of articleData.tags; trackBy: trackByTag" class="article__tag">{{ tag }}</div>
			</section>

			<section class="category__info info">
				<div [style.background-color]="categories[articleData.category].color" class="info__tag">
					{{ categories[articleData.category].name }}
				</div>
				<div class="info__dot"></div>
				<div class="info__date">{{ formattedDate || '' | date: 'd MMM, y' }}</div>
			</section>

			<img [src]="articleData.cover" alt="article cover" class="article__cover" />

			<p class="article__desc">{{ articleData.subtitle }}</p>

			<section *ngFor="let author of articleData.authors; trackBy: trackByAuthor" class="article__author-list">
				<div class="author">
					<tk-avatar [src]="author.avatar" size="78px" class="author__avatar" />
					<div>
						<div class="author__name">{{ author.name }}</div>
						<div class="author__position">{{ author.position }}</div>
					</div>
				</div>
			</section>

			<hr class="article__delimiter" />

			<section *ngFor="let block of articleContent.blocks; trackBy: trackByBlock" class="article__content">
				<ng-container [ngSwitch]="block.type">
					<div *ngSwitchCase="BlockType.HEADER">
						<ng-container [ngSwitch]="block.data.level">
							<h2 *ngSwitchCase="2" [innerHTML]="block.data.text"></h2>
							<h3 *ngSwitchCase="3" [innerHTML]="block.data.text"></h3>
							<h4 *ngSwitchCase="4" [innerHTML]="block.data.text"></h4>
							<h5 *ngSwitchCase="5" [innerHTML]="block.data.text"></h5>
						</ng-container>
					</div>

					<p *ngSwitchCase="BlockType.PARAGRAPH" [innerHTML]="block.data.text"></p>

					<ul *ngSwitchCase="BlockType.LIST" [class]="block.data.style">
						<li *ngFor="let item of block.data.items; trackBy: trackByTag" [innerHTML]="item"></li>
					</ul>

					<div *ngSwitchCase="BlockType.DELIMITER" class="article__delimiter"></div>

					<div *ngSwitchCase="BlockType.CODE" class="test">
						<pre class="code-block">
							<code [highlight]="block.data.code!" class="code-block__item"></code>
						</pre>
					</div>

					<div *ngSwitchCase="BlockType.IMAGE">
						<div [class.image--fullsize]="block.data.stretched" class="image">
							<img alt="block image" src="{{ block.data.file.url }}" />
							<div [innerHTML]="block.data.caption" class="image__caption"></div>
						</div>
					</div>

					<div *ngSwitchCase="BlockType.EMBED" class="embed">
						<iframe
							[height]="block.data.height"
							[src]="block.data.embed | safe: 'resourceUrl'"
							[width]="block.data.width"
						></iframe>
						<p *ngIf="block.data.caption" class="image__caption">{{ block.data.caption }}</p>
					</div>

					<p *ngSwitchCase="BlockType.INCUT" [innerHTML]="block.data.text" class="incut"></p>

					<ng-container *ngSwitchCase="BlockType.NUMBERS">
						<div
							*ngIf="blockAsNumbers(block) as blockNumbers"
							[class.numbers--multiple]="blockNumbers.data.length > 1"
							class="numbers"
						>
							<div
								*ngFor="let item of blockNumbers.data; trackBy: trackByNumberBlock"
								class="numbers__block"
							>
								<span [innerHTML]="item.head" class="numbers__head"></span>
								<p [innerHTML]="item.text" class="numbers__text"></p>
							</div>
						</div>
					</ng-container>

					<div *ngSwitchCase="BlockType.QUOTE" class="quote">
						<p [innerHTML]="block.data.text" class="quote__text"></p>
						<div class="author">
							<tk-avatar [src]="block.data.authorAvatarPath" size="78px" class="author__avatar" />
							<div>
								<p class="author__name">{{ block.data.authorName }}</p>
								<span class="author__position">{{ block.data.authorPosition }}</span>
							</div>
						</div>
					</div>
					<div *ngSwitchDefault>Default</div>
				</ng-container>
			</section>
			<section class="article__tags">
				<div *ngFor="let tag of articleData.tags; trackBy: trackByTag" class="article__tag">{{ tag }}</div>
			</section>
		</ng-container>
	</section>
} @else {
	<tk-loader color="WHITE" width="FULL" />
}
