import { BlockTool, BlockToolData } from '@editorjs/editorjs';
import { NumbersToolData } from '@models/editor-components';
import { removeNbsp } from './remove-nbsp';

export class NumbersTool implements BlockTool {
	data: NumbersToolData[];
	wrapper: HTMLElement | null = null;
	items: HTMLElement | null = null;
	counter = 1;

	constructor({ data }: BlockToolData) {
		this.data = data;
	}
	static get toolbox() {
		return {
			title: 'Цифры',
			icon: '3',
		};
	}
	render() {
		this.wrapper = document.createElement('div');
		this.wrapper.classList.add('numbers-block');

		this.items = document.createElement('div');
		this.items.classList.add('items');

		if (this.data?.length) {
			this.data.map(item => {
				this.appendItem(item);
			});
		} else {
			this.appendItem();
		}

		this.wrapper.appendChild(this.items);

		if (this.counter < 4) {
			const button = document.createElement('button');
			button.innerHTML = 'Добавить';

			button.addEventListener('click', event => {
				this.appendItem();

				if (this.counter === 4) {
					this.wrapper?.removeChild(button);
				}
			});

			this.wrapper.appendChild(button);
		}

		return this.wrapper;
	}

	appendItem(item?: NumbersToolData) {
		const block = document.createElement('div');
		block.classList.add('block');

		if (this.counter > 1) {
			const deleteButton = document.createElement('button');
			deleteButton.innerHTML = 'X';
			deleteButton.classList.add('delete');

			deleteButton.addEventListener('click', event => {
				this.deleteItem(block);
			});
			block.appendChild(deleteButton);
		}

		const head = document.createElement('div');
		head.classList.add('head');
		head.contentEditable = 'true';
		head.innerHTML = item?.head || '';
		block.appendChild(head);

		const text = document.createElement('div');
		text.classList.add('text');
		text.contentEditable = 'true';
		text.innerHTML = item?.text || '';
		block.appendChild(text);

		this.items!.appendChild(block);
		this.counter++;
	}

	deleteItem(item: HTMLDivElement) {
		this.items!.removeChild(item);
		this.counter--;
	}

	save(blockContent: HTMLInputElement) {
		this.data = [];

		Array.from(blockContent.getElementsByClassName('block')).map(item => {
			const head = item.getElementsByClassName('head');
			const text = item.getElementsByClassName('text');

			this.data.push({
				head: removeNbsp(head[0].innerHTML),
				text: removeNbsp(text[0].innerHTML),
			});
		});

		return this.data;
	}
}
