<section class="header">
	<h3>Теги</h3>
	<a routerLink="edit" tkButton>Создать тег</a>
</section>

<cdk-table *ngIf="!(loading$ | async); else loader" [dataSource]="tagList$">
	<cdk-header-row *cdkHeaderRowDef="columns"></cdk-header-row>

	<ng-container cdkColumnDef="name">
		<cdk-header-cell *cdkHeaderCellDef> Имя </cdk-header-cell>

		<cdk-cell *cdkCellDef="let row">
			<div class="space-between">
				{{ row.name }}
				<div class="hovered_row--show">
					<button (click)="deleteAuthor($event, row.id)" class="control control--delete">
						<tk-icon icon="trash" />
					</button>
				</div>
			</div>
		</cdk-cell>
	</ng-container>

	<cdk-row *cdkRowDef="let row; columns: columns" (click)="editTag(row)" class="pointer"></cdk-row>
</cdk-table>

<ng-template #loader>
	<tk-loader width="FULL"></tk-loader>
</ng-template>
