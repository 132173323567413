import { HttpErrorResponse } from '@angular/common/http';

const notObjectCheck = (value: unknown[] | string | unknown, fallback: string) => {
	if (Array.isArray(value)) return checkForValidString(value?.[0], fallback);
	else if (typeof value === 'string') return value;

	return fallback;
};

interface ErrorMessageType {
	msg?: string;
	message?: string;
}

const checkForValidString = (
	errorObject: string | ErrorMessageType | unknown[],
	fallback: string,
): string | undefined => {
	if (typeof errorObject === 'string' && errorObject.toLocaleLowerCase().includes('html')) return fallback;

	if (typeof errorObject === 'object') {
		let value: string;
		const val = errorObject as ErrorMessageType;

		if (Object.keys(errorObject).includes('msg') || Object.keys(errorObject).includes('message')) {
			value = (val['msg'] ?? val['message']) || '';
		} else {
			value = (Object.values(errorObject)?.[0] as string) || '';
		}

		return notObjectCheck(value, fallback);
	}

	return notObjectCheck(errorObject, fallback);
};

export const messageError = (err: HttpErrorResponse | string, fallback = 'Error') => {
	if (typeof err !== 'string' && err.status >= 500) {
		return 'Ошибка сервера';
	}

	return checkForValidString(typeof err === 'string' ? err : err.error, fallback);
};
export const titleError = (err?: HttpErrorResponse | string) => {
	return err && typeof err === 'object' ? Object.keys(err.error)[0] : err;
};
