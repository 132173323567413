import { APP_ID, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AdminMenuComponent } from '@technokratos-admin/components/menu/admin-menu.component';
import { LoginComponent } from '@pages-admin/login/login.page';
import { ArticlesPage } from '@pages-admin/articles/articles.page';
import { ArticleEditPage } from '@pages-admin/articles/article-edit/article-edit.page';
import { MailPage } from '@pages-admin/mail/mail.component';
import { AuthorsPage } from '@pages-admin/authors/authors.page';
import { TagsPage } from '@pages-admin/tags/tags.page';
import { AdminModalContainerComponent } from '@technokratos-admin/components/modals/admin-modal-container/admin-modal-container.component';
import { AdminPreviewComponent } from '@pages-admin/articles/article-edit/components/preview/preview.component';
import { PreviewModalContainerComponent } from '@technokratos-admin/components/modals/preview-modal-container/preview-modal-container.component';
import { ConfirmModalComponent } from '@technokratos-admin/components/modals/confirm-modal/confirm-modal.component';
import { UnsavedChangesModalComponent } from '@technokratos-admin/components/modals/unsaved-changes-modal/unsaved-changes-modal.component';
import { ComponentsModule } from '@components/components.module';
import { LoginErrorComponent } from '@pages-admin/login-error/login-error.component';
import { TkButtonModule } from '@technokratos/components-angular/button';
import localeRu from '@angular/common/locales/ru';
import { registerLocaleData } from '@angular/common';
import { FormFieldInfoComponent } from '@ui/form-field-info/form-field-info.component';
import { FormFieldErrorComponent } from '@ui/form-field-error/form-field-error.component';
import { AvatarUploadFormControlComponent } from '@technokratos-admin/components/forms/avatar-upload-form-control/avatar-upload-form-control.component';
import { EditAuthorPage } from '@pages-admin/authors/edit-author/edit-author.page';
import { EditTagPage } from '@pages-admin/tags/edit-tag/edit-tag.page';
import { AvatarComponent } from '@ui/avatar/avatar.component';

registerLocaleData(localeRu);

export let InjectorInstance: Injector;

@NgModule({
	declarations: [
		AdminMenuComponent,
		LoginComponent,
		ArticlesPage,
		ArticleEditPage,
		MailPage,
		AuthorsPage,
		TagsPage,
		AdminModalContainerComponent,
		AdminPreviewComponent,
		PreviewModalContainerComponent,
		ConfirmModalComponent,
		UnsavedChangesModalComponent,
		LoginErrorComponent,
		AvatarUploadFormControlComponent,
		EditAuthorPage,
		EditTagPage,
	],
	imports: [
		TkButtonModule,
		DragDropModule,
		HighlightModule,
		ComponentsModule,
		FormFieldInfoComponent,
		FormFieldErrorComponent,
		AvatarComponent,
	],
	providers: [
		CookieService,
		{ provide: APP_ID, useValue: 'serverApp' },
		{
			provide: LOCALE_ID,
			useValue: 'ru-RU',
		},
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: {
				coreLibraryLoader: () => import('highlight.js/lib/core'),
				languages: {
					typescript: () => import('highlight.js/lib/languages/typescript'),
					css: () => import('highlight.js/lib/languages/css'),
					xml: () => import('highlight.js/lib/languages/xml'),
					json: () => import('highlight.js/lib/languages/json'),
					python: () => import('highlight.js/lib/languages/python'),
					java: () => import('highlight.js/lib/languages/java'),
					sql: () => import('highlight.js/lib/languages/sql'),
				},
			},
		},
	],
	exports: [AdminMenuComponent, AvatarUploadFormControlComponent],
})
export class AppModule {
	constructor(private injector: Injector) {
		InjectorInstance = this.injector;
	}
}
