import { Component, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { TkButtonModule } from '@technokratos/components-angular/button';
import { ImageService } from '@technokratos-admin/services/image.service';
import { IconComponent } from '@ui/icon/icon.component';
import { TypeEnum } from '@models/enums/type.enum';
import { Image } from '@models/image';
import { ToastService } from '@services/toast.service';

export type CropModalDialogResult = {
	hasUpdates: boolean;
	image?: Image | null;
};

@Component({
	selector: 'tk-crop-modal',
	standalone: true,
	imports: [CommonModule, ImageCropperComponent, TkButtonModule, IconComponent],
	templateUrl: './crop-modal.component.html',
	styleUrls: ['./crop-modal.component.sass', '/apps/technokratos-admin/src/styles/common.sass'],
})
export class CropModalComponent {
	private dialogRef = inject(DialogRef<CropModalDialogResult>);
	private imageService = inject(ImageService);
	private toast = inject(ToastService);

	http = inject(HttpClient);
	dialogData: {
		imageUrl?: string;
		imageEvent?: Event;
		imageName: string;
	} = inject(DIALOG_DATA);

	croppedImg?: Blob;
	loading = signal(false);

	imageCropped(event: ImageCroppedEvent) {
		if (!event.blob) {
			return;
		}

		this.croppedImg = event.blob;
	}

	submit() {
		if (!this.croppedImg || this.loading()) {
			return;
		}

		this.loading.set(true);

		const file = new File([this.croppedImg], this.dialogData.imageName);

		this.imageService
			.uploadImage(file, TypeEnum.AUTHORAVATAR)
			.pipe(finalize(() => this.loading.set(false)))
			.subscribe({
				next: image => {
					this.dialogRef.close({
						hasUpdates: true,
						image,
					});
				},
				error: err => {
					this.toast.error(err.error.image[0]);
					this.dialogRef.close({ hasUpdates: false });
				},
			});
	}

	delete() {
		if (this.loading()) {
			return;
		}

		this.dialogRef.close({
			hasUpdates: true,
			image: null,
		});
	}

	close() {
		this.dialogRef.close({ hasUpdates: false });
	}
}
