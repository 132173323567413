import { API, InlineTool, InlineToolConstructorOptions } from '@editorjs/editorjs';

export class InlineHeaderTool implements InlineTool {
	private button: HTMLButtonElement | null;
	private _state: boolean;
	private api: API;
	readonly tag: string;
	readonly class: string;

	static get isInline() {
		return true;
	}

	get state() {
		return this._state;
	}

	set state(state) {
		this._state = state;

		this.button!.classList.toggle(this.api.styles.inlineToolButtonActive, state);
	}

	constructor({ api }: InlineToolConstructorOptions) {
		this.api = api;
		this.button = null;
		this._state = false;

		this.tag = 'SPAN';
		this.class = 'inline-header';
	}

	render() {
		this.button = document.createElement('button');
		this.button.type = 'button';
		this.button.innerHTML = 'Цифра';
		this.button.classList.add(this.api.styles.inlineToolButton);

		return this.button;
	}

	surround(range: Range) {
		if (this.state) {
			this.unwrap(range);
			return;
		}

		this.wrap(range);
	}

	wrap(range: Range) {
		const selectedText = range.extractContents();
		const mark = document.createElement(this.tag);

		mark.classList.add(this.class);
		mark.appendChild(selectedText);
		range.insertNode(mark);

		this.api.selection.expandToTag(mark);
	}

	unwrap(range: Range) {
		const mark = this.api.selection.findParentTag(this.tag, this.class);
		const text = range.extractContents();

		mark!.remove();

		range.insertNode(text);
	}

	checkState() {
		const mark = this.api.selection.findParentTag(this.tag);

		this.state = !!mark;
		return this.state;
	}
}
