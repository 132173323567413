<div class="header">
	<h3>Почта</h3>
	<tk-button (click)="onDownload()"><tk-icon icon="export" />Экспортировать в Excel</tk-button>
</div>
<cdk-table *ngIf="!(loading$ | async); else loader" [dataSource]="emailList$" cdkDropList>
	<cdk-header-row *cdkHeaderRowDef="columns"></cdk-header-row>
	<ng-container cdkColumnDef="email">
		<cdk-header-cell *cdkHeaderCellDef> Электронная почта</cdk-header-cell>

		<cdk-cell *cdkCellDef="let row" class="email"> {{ row.email }} </cdk-cell>
	</ng-container>

	<ng-container cdkColumnDef="created_at">
		<cdk-header-cell (click)="changeSort()" *cdkHeaderCellDef class="sort__container">
			<tk-icon [class.sort__arrow--reversed]="!asc" class="sort__arrow" icon="sort-arrow" />
			Дата подписки
		</cdk-header-cell>

		<cdk-cell *cdkCellDef="let row"> {{ row.created_at }} </cdk-cell>
	</ng-container>
	<cdk-row *cdkRowDef="let row; columns: columns"></cdk-row>
</cdk-table>

<ng-template #loader>
	<tk-loader width="FULL"></tk-loader>
</ng-template>
