import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'tk-login',
	templateUrl: './login.page.html',
	styleUrls: ['/apps/technokratos-admin/src/styles/common.sass', './login.page.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
	constructor(
		public authService: AuthService,
		private route: ActivatedRoute,
		private router: Router,
	) {
		const token = this.route.snapshot.queryParams['access'];

		if (token) {
			this.authService.authorize(token);
			const url = this.authService.lastUrl === '/login' ? '/articles' : this.authService.lastUrl || '/articles';
			this.router.navigateByUrl(url);
		}
	}
}
