import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@environments/environment';
import { LocalStorage, storageService } from '@utils/local-storage';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	@LocalStorage() token: string | undefined | null;
	@LocalStorage() lastUrl: string | undefined;

	redirectUrl = '';
	errorUrl = '';

	constructor() {
		const platformId = inject(PLATFORM_ID);

		if (isPlatformBrowser(platformId)) {
			this.redirectUrl = `${location.origin}/login`;
			this.errorUrl = `${location.origin}/login-error`;
		}
	}

	get authUrl() {
		return `${environment.apiUrl}/oauth2/login/google-oauth2?redirect_to=${encodeURIComponent(
			this.redirectUrl,
		)}&error_to=${encodeURIComponent(this.errorUrl)}`;
	}

	static getToken() {
		return storageService.getStorageValue('token');
	}

	authorize(token: string): void {
		this.token = token;
		localStorage.getItem('token');
	}

	logout(): void {
		storageService.clearStorageByKey('token');
		storageService.clearStorageByKey('user');
	}
}
