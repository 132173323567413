import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { ComponentType, Overlay } from '@angular/cdk/overlay';
import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { ModalContainerComponent } from '@modals/modal-container/modal-container.component';

type DialogConfigType<T> = DialogConfig<unknown, DialogRef<unknown, T>>;

export const customOverlayClass = 'custom-overlay-class';
const darkBackDrop = 'dark-backdrop';
const transparentBackDrop = 'transparent-backdrop';

const FullWidthConfig = {
	width: '100%',
	height: '100%',
	maxWidth: '100vw',
	backdropClass: 'cdk-transparent-backdrop',
	disableClose: true,
};

const panelClass = <T>(config: DialogConfigType<T> = {}) => {
	return {
		panelClass: [
			customOverlayClass,
			...(Array.isArray(config.panelClass) ? config.panelClass : [config.panelClass ?? '']),
		],
	};
};

const backdropClass = <T>(config: DialogConfigType<T>, openedDialogs: readonly DialogRef[]) => {
	const transparentBackdrop = openedDialogs.length ? transparentBackDrop : darkBackDrop;

	return {
		backdropClass: config.backdropClass ? config.backdropClass : transparentBackdrop,
	};
};

@Injectable({
	providedIn: 'root',
})
export class DialogService {
	private windowRef = inject(WINDOW);
	private dialog = inject(Dialog);
	private overlay = inject(Overlay);

	overlayConfig<T>(config: DialogConfigType<T>): DialogConfigType<T> {
		return {
			...config,
			...panelClass(config),
			...backdropClass(config, this.dialog.openDialogs),
		};
	}

	openDialog<T>(component: ComponentType<T>, config: DialogConfigType<T> = {}) {
		return this.dialog.open(component, {
			maxWidth: 'none',
			container: ModalContainerComponent,
			autoFocus: false,
			closeOnNavigation: false,
			...this.overlayConfig<T>(config),
		});
	}

	openStandAloneDialog<T>(component: ComponentType<T>, config: DialogConfigType<T> = {}) {
		return this.dialog.open(component, {
			...this.overlayConfig<T>(config),
		});
	}

	openFullWidthDialog<T>(component: ComponentType<T>, config: DialogConfigType<T> = {}): DialogRef<unknown, T> {
		return this.dialog.open(component, {
			...FullWidthConfig,
			...config,
			...panelClass({ panelClass: 'custom-modalBox' }),
		});
	}

	openDialogWithHeaderSpace<T>(component: ComponentType<T>, config: DialogConfigType<T> = {}) {
		this.windowRef.scroll(0, 0);

		return this.openDialog(component, {
			backdropClass: [darkBackDrop, 'with-header-overlay'],
			disableClose: true,
			scrollStrategy: this.overlay.scrollStrategies.block(),
			...config,
		});
	}
}
