import { inject } from '@angular/core';
import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';

export const AuthInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
	const auth = inject(AuthService);
	const router = inject(Router);

	const addToken = (request: HttpRequest<unknown>) => {
		const headers = auth.token ? request.headers.set('Authorization', `Bearer ${auth.token}`) : request.headers;

		return request.clone({
			headers,
		});
	};

	return next(addToken(request)).pipe(
		catchError((err: HttpErrorResponse) => {
			if (err.status === 401) {
				auth.token = '';
				auth.lastUrl = router.url;
				router.navigateByUrl('/login');
			}

			return throwError(() => err);
		}),
	);
};
