import { InjectionToken } from '@angular/core';
import { StringHandler } from '@technokratos/components-angular/icon';
import { tkIcons } from '@ui/icon/icon-list';

export const tkIconsPath: StringHandler<string> = (name = '') =>
	name && name.includes('.svg#') ? name : `assets/sprite.svg#${name}`;

export const TK_ICONS_PATH: InjectionToken<StringHandler<string>> = new InjectionToken<StringHandler<string>>(
	'A handler to retrieve USE id for icon by name',
	{
		factory: () => tkIconsPath,
	},
);

export const TK_ICONS = new InjectionToken<Record<string, string>>(
	'A key/value dictionary of icon names and src to be defined with TkSvgService',
	{
		factory: () => tkIcons,
	},
);
