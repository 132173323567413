import * as i1 from '@angular/cdk/overlay';
import { OverlayModule } from '@angular/cdk/overlay';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Directive, ChangeDetectorRef, Component, ViewEncapsulation, ChangeDetectionStrategy, Optional, Self, Inject, Input, ViewChild, ContentChild, ContentChildren, HostListener, NgModule } from '@angular/core';
import * as i3 from '@technokratos/components-angular/icon';
import { TkIconModule } from '@technokratos/components-angular/icon';
import { OptionParentToken, TkOptionModule } from '@technokratos/components-angular/option';
import { AbstractTkControl } from '@technokratos/components-angular/core';
import * as i5 from '@angular/forms';
import { NgControl } from '@angular/forms';
import { ReplaySubject, merge, Subject } from 'rxjs';
import { startWith, switchMap, debounceTime, takeUntil } from 'rxjs/operators';
import { TkSuffix } from '@technokratos/components-angular/form-field';
import { FocusKeyManager, A11yModule } from '@angular/cdk/a11y';
import { TAB } from '@angular/cdk/keycodes';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as i4 from '@technokratos/components-angular/panel';
import { TkPanelModule } from '@technokratos/components-angular/panel';

class TkSelectTrigger {
}
TkSelectTrigger.ɵfac = function TkSelectTrigger_Factory(t) { return new (t || TkSelectTrigger)(); };
TkSelectTrigger.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: TkSelectTrigger, selectors: [["tk-select-trigger"], ["", "tkSelectTrigger", ""]], hostVars: 2, hostBindings: function TkSelectTrigger_HostBindings(rf, ctx) { if (rf & 2) {
        i0.ɵɵclassProp("tk-select-trigger", true);
    } } });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkSelectTrigger, [{
        type: Directive,
        args: [{
                selector: 'tk-select-trigger, [tkSelectTrigger]',
                host: {
                    '[class.tk-select-trigger]': 'true',
                },
            }]
    }], null, null); })();

const _c0 = ["trigger"];
function TkSelect_span_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 10);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r2.placeholder);
} }
function TkSelect_span_5_span_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r6.triggerValue || "\u00A0");
} }
function TkSelect_span_5_ng_content_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵprojection(0, 0, ["*ngSwitchCase", "true"]);
} }
function TkSelect_span_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 11);
    i0.ɵɵtemplate(1, TkSelect_span_5_span_1_Template, 2, 1, "span", 12);
    i0.ɵɵtemplate(2, TkSelect_span_5_ng_content_2_Template, 1, 0, "ng-content", 13);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngSwitch", !!ctx_r3.customTrigger);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngSwitchCase", true);
} }
function TkSelect_span_10_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 14);
    i0.ɵɵprojection(1, 1);
    i0.ɵɵelementEnd();
} }
function TkSelect_ng_template_11_Template(rf, ctx) { if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tk-panel", 15);
    i0.ɵɵlistener("keydown", function TkSelect_ng_template_11_Template_tk_panel_keydown_0_listener($event) { i0.ɵɵrestoreView(_r9); const ctx_r8 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r8.handleKeydown($event)); });
    i0.ɵɵprojection(1, 2);
    i0.ɵɵelementEnd();
} }
const _c1 = [[["tk-select-trigger"], ["", "tkSelectTrigger", ""]], [["tk-suffix"], ["", "tkSuffix", ""]], "*"];
const _c2 = ["tk-select-trigger, [tkSelectTrigger]", "tk-suffix, [tkSuffix]", "*"];
let nextUniqueId = 0;
class TkSelect extends AbstractTkControl {
    constructor(control, changeDetectorRef, elementRef, overlay) {
        super(control, changeDetectorRef);
        this.elementRef = elementRef;
        this.overlay = overlay;
        this.id = `tk-select-${nextUniqueId++}`;
        this.placeholder = '';
        this.scrollStrategy = this.overlay.scrollStrategies.reposition();
        this.overlayPanelClass = '';
        this.positions = [
            {
                originX: 'start',
                originY: 'bottom',
                overlayX: 'start',
                overlayY: 'top',
            },
            {
                originX: 'start',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'bottom',
            },
        ];
        this.isOpen = false;
        this.controlType = 'select';
        this.options = [];
        this.options$ = new ReplaySubject(1);
        // Обсервабл, в котором будут идти все клики по опциям
        this.optionSelectionChanges = this.options$.pipe(startWith(this.options), switchMap(() => merge(...this.options.map(option => {
            return option.itemClick;
        }))));
        this.destroy$ = new Subject();
        this._multiple = false;
        if (control) {
            control.valueAccessor = this;
        }
    }
    get multiple() {
        return this._multiple;
    }
    set multiple(value) {
        this._multiple = coerceBooleanProperty(value);
    }
    get selectedOptions() {
        if (!this.options?.length || this.isEmpty) {
            return [];
        }
        return this.multiple
            ? this.value.map(v => this.getOption(v))
            : [this.getOption(this.value)].filter(Boolean);
    }
    get isEmpty() {
        if (!this.multiple) {
            if (this.nullOption &&
                (this.value === null || this.value === undefined)) {
                return false;
            }
        }
        return this.multiple
            ? !Array.isArray(this.value) || this.value.length === 0
            : !this.value;
    }
    get nullOption() {
        return this.options.find(o => o.value === null);
    }
    get labelFloat() {
        return !this.isEmpty && !this.readonly;
    }
    get triggerValue() {
        if (this.nullOption &&
            !this.multiple &&
            (this.value === null || this.value === undefined)) {
            return this.nullOption.viewValue;
        }
        if (this.isEmpty) {
            return '';
        }
        return this.selectedOptions.map(o => o.viewValue).join(', ');
    }
    get hasSuffix() {
        return this.suffix.length > 0;
    }
    updateSelectedOptions() {
        this.options.forEach(option => {
            option.selected = false;
            option.cdr.markForCheck();
        });
        this.selectedOptions
            .map(v => this.getOption(v.value))
            .forEach(option => {
            option.selected = true;
            option.cdr.markForCheck();
        });
    }
    getOption(value) {
        return this.options.find(o => o.value === value);
    }
    ngAfterContentInit() {
        // Нужно обновить triggerValue
        this.options$
            .pipe(debounceTime(0), takeUntil(this.destroy$))
            .subscribe((res) => {
            this.keyManager = new FocusKeyManager(res).withWrap();
            this.updateSelectedOptions();
            this.cdr.markForCheck();
        });
        // По клику обновляем модель внутри
        this.optionSelectionChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(option => {
            if (!this.multiple) {
                this.close();
            }
            this.optionClick(option);
        });
    }
    optionClick(option) {
        if (this.multiple) {
            option.selected = !option.selected;
            option.cdr.markForCheck();
            this.value = this.options
                .filter(o => o.selected)
                .map(o => o.value);
        }
        else {
            this.value = option.value;
            this.options.forEach(o => {
                o.selected = o === option;
                o.cdr.markForCheck();
            });
        }
        this.cdr.markForCheck();
        this.propagateChanges();
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
    writeValue(value) {
        this.value = value;
        this.updateSelectedOptions();
        this.cdr.markForCheck();
    }
    open() {
        if (this.disabled) {
            return;
        }
        this.setFirstActive();
        this.focused = true;
        this.triggerRect = this.trigger.nativeElement.getBoundingClientRect();
        this.isOpen = true;
    }
    setFirstActive() {
        this.keyManager.setFirstItemActive();
    }
    handleKeydown(event) {
        if (!this.isOpen) {
            return;
        }
        if (event.keyCode === TAB) {
            event.preventDefault();
            if (this.keyManager.activeItemIndex === this.options.length - 1) {
                this.close();
            }
            this.keyManager.setNextItemActive();
        }
        else {
            this.keyManager.onKeydown(event);
        }
    }
    close() {
        this.focused = false;
        this.isOpen = false;
        this.onTouchedCallback();
    }
    register(option) {
        this.options = [...this.options, option];
        this.options$.next(this.options);
    }
    deregister(option) {
        this.options = this.options.filter(o => o !== option);
        this.options$.next(this.options);
    }
    propagateChanges() {
        if (!this.options) {
            return;
        }
        this.onChangeCallback(this.value);
    }
}
TkSelect.ɵfac = function TkSelect_Factory(t) { return new (t || TkSelect)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.Overlay)); };
TkSelect.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TkSelect, selectors: [["tk-select"]], contentQueries: function TkSelect_ContentQueries(rf, ctx, dirIndex) { if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, TkSelectTrigger, 5);
        i0.ɵɵcontentQuery(dirIndex, TkSuffix, 4);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.customTrigger = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.suffix = _t);
    } }, viewQuery: function TkSelect_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.trigger = _t.first);
    } }, hostVars: 6, hostBindings: function TkSelect_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("click", function TkSelect_click_HostBindingHandler() { return ctx.open(); })("keydown", function TkSelect_keydown_HostBindingHandler() { return ctx.handleKeydown(); });
    } if (rf & 2) {
        i0.ɵɵclassProp("tk-select", true)("tk-select--multiple", ctx.multiple)("tk-select--non-multiple", !ctx.multiple);
    } }, inputs: { id: "id", placeholder: "placeholder", ariaLabel: "ariaLabel", scrollStrategy: "scrollStrategy", overlayPanelClass: "overlayPanelClass", positions: "positions", multiple: "multiple" }, exportAs: ["tkSelect"], features: [i0.ɵɵProvidersFeature([
            {
                provide: AbstractTkControl,
                useExisting: TkSelect,
            },
            {
                provide: OptionParentToken,
                useExisting: TkSelect,
            },
        ]), i0.ɵɵInheritDefinitionFeature], ngContentSelectors: _c2, decls: 12, vars: 14, consts: [["type", "button", "cdk-overlay-origin", "", 1, "tk-select-trigger", "tk-input"], ["origin", "cdkOverlayOrigin", "trigger", ""], [1, "tk-select__value", 3, "ngSwitch"], ["class", "tk-select__placeholder", 4, "ngSwitchCase"], ["class", "tk-select__value-text", 3, "ngSwitch", 4, "ngSwitchCase"], [1, "tk-select__suffix-wrapper"], [1, "tk-select__arrow"], ["icon", "tkDropdownArrow"], ["class", "tk-select__suffix", 4, "ngIf"], ["cdk-connected-overlay", "", "cdkConnectedOverlayLockPosition", "", "cdkConnectedOverlayHasBackdrop", "", "cdkConnectedOverlayBackdropClass", "cdk-overlay-transparent-backdrop", 3, "cdkConnectedOverlayPanelClass", "cdkConnectedOverlayOrigin", "cdkConnectedOverlayPush", "cdkConnectedOverlayOpen", "cdkConnectedOverlayPositions", "cdkConnectedOverlayMinWidth", "cdkConnectedOverlayScrollStrategy", "backdropClick", "attach", "detach"], [1, "tk-select__placeholder"], [1, "tk-select__value-text", 3, "ngSwitch"], [4, "ngSwitchDefault"], [4, "ngSwitchCase"], [1, "tk-select__suffix"], [1, "tk-select__panel", 3, "keydown"]], template: function TkSelect_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef(_c1);
        i0.ɵɵelementStart(0, "button", 0, 1)(3, "span", 2);
        i0.ɵɵtemplate(4, TkSelect_span_4_Template, 2, 1, "span", 3);
        i0.ɵɵtemplate(5, TkSelect_span_5_Template, 3, 2, "span", 4);
        i0.ɵɵtext(6, " \u00A0 ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "span", 5)(8, "span", 6);
        i0.ɵɵelement(9, "tk-icon", 7);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(10, TkSelect_span_10_Template, 2, 0, "span", 8);
        i0.ɵɵelementEnd()();
        i0.ɵɵtemplate(11, TkSelect_ng_template_11_Template, 2, 0, "ng-template", 9);
        i0.ɵɵlistener("backdropClick", function TkSelect_Template_ng_template_backdropClick_11_listener() { return ctx.close(); })("attach", function TkSelect_Template_ng_template_attach_11_listener() { return ctx.setFirstActive(); })("detach", function TkSelect_Template_ng_template_detach_11_listener() { return ctx.close(); });
    } if (rf & 2) {
        const _r0 = i0.ɵɵreference(1);
        i0.ɵɵattribute("aria-label", ctx.ariaLabel);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngSwitch", ctx.isEmpty);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngSwitchCase", true);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngSwitchCase", false);
        i0.ɵɵadvance(3);
        i0.ɵɵclassProp("tk-select__arrow--rotate", ctx.isOpen);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.hasSuffix);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("cdkConnectedOverlayPanelClass", ctx.overlayPanelClass)("cdkConnectedOverlayOrigin", _r0)("cdkConnectedOverlayPush", true)("cdkConnectedOverlayOpen", ctx.isOpen)("cdkConnectedOverlayPositions", ctx.positions)("cdkConnectedOverlayMinWidth", ctx.triggerRect == null ? null : ctx.triggerRect.width)("cdkConnectedOverlayScrollStrategy", ctx.scrollStrategy);
    } }, dependencies: [i2.NgIf, i2.NgSwitch, i2.NgSwitchCase, i2.NgSwitchDefault, i1.CdkConnectedOverlay, i1.CdkOverlayOrigin, i3.TkIcon, i4.TkPanel], encapsulation: 2, changeDetection: 0 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkSelect, [{
        type: Component,
        args: [{ selector: 'tk-select', providers: [
                    {
                        provide: AbstractTkControl,
                        useExisting: TkSelect,
                    },
                    {
                        provide: OptionParentToken,
                        useExisting: TkSelect,
                    },
                ], exportAs: 'tkSelect', host: {
                    '[class.tk-select]': 'true',
                    '[class.tk-select--multiple]': 'multiple',
                    '[class.tk-select--non-multiple]': '!multiple',
                }, encapsulation: ViewEncapsulation.None, changeDetection: ChangeDetectionStrategy.OnPush, template: "<button\n\tclass=\"tk-select-trigger tk-input\"\n\ttype=\"button\"\n\tcdk-overlay-origin\n\t#origin=\"cdkOverlayOrigin\"\n\t#trigger\n\t[attr.aria-label]=\"ariaLabel\"\n>\n\t<span class=\"tk-select__value\" [ngSwitch]=\"isEmpty\">\n\t\t<span class=\"tk-select__placeholder\" *ngSwitchCase=\"true\">{{\n\t\t\tplaceholder\n\t\t}}</span>\n\t\t<span\n\t\t\tclass=\"tk-select__value-text\"\n\t\t\t*ngSwitchCase=\"false\"\n\t\t\t[ngSwitch]=\"!!customTrigger\"\n\t\t>\n\t\t\t<span *ngSwitchDefault>{{ triggerValue || '\\u00A0' }}</span>\n\t\t\t<ng-content\n\t\t\t\tselect=\"tk-select-trigger, [tkSelectTrigger]\"\n\t\t\t\t*ngSwitchCase=\"true\"\n\t\t\t></ng-content>\n\t\t</span>\n\t\t&nbsp;\n\t</span>\n\t<span class=\"tk-select__suffix-wrapper\">\n\t\t<span\n\t\t\tclass=\"tk-select__arrow\"\n\t\t\t[class.tk-select__arrow--rotate]=\"isOpen\"\n\t\t>\n\t\t\t<tk-icon icon=\"tkDropdownArrow\"></tk-icon>\n\t\t</span>\n\t\t<span class=\"tk-select__suffix\" *ngIf=\"hasSuffix\">\n\t\t\t<ng-content select=\"tk-suffix, [tkSuffix]\"></ng-content>\n\t\t</span>\n\t</span>\n</button>\n\n<ng-template\n\tcdk-connected-overlay\n\tcdkConnectedOverlayLockPosition\n\tcdkConnectedOverlayHasBackdrop\n\tcdkConnectedOverlayBackdropClass=\"cdk-overlay-transparent-backdrop\"\n\t[cdkConnectedOverlayPanelClass]=\"overlayPanelClass\"\n\t[cdkConnectedOverlayOrigin]=\"origin\"\n\t[cdkConnectedOverlayPush]=\"true\"\n\t[cdkConnectedOverlayOpen]=\"isOpen\"\n\t[cdkConnectedOverlayPositions]=\"positions\"\n\t[cdkConnectedOverlayMinWidth]=\"triggerRect?.width!\"\n\t[cdkConnectedOverlayScrollStrategy]=\"scrollStrategy\"\n\t(backdropClick)=\"close()\"\n\t(attach)=\"setFirstActive()\"\n\t(detach)=\"close()\"\n>\n\t<tk-panel class=\"tk-select__panel\" (keydown)=\"handleKeydown($event)\">\n\t\t<ng-content></ng-content>\n\t</tk-panel>\n</ng-template>\n" }]
    }], function () { return [{ type: i5.NgControl, decorators: [{
                type: Optional
            }, {
                type: Self
            }, {
                type: Inject,
                args: [NgControl]
            }] }, { type: i0.ChangeDetectorRef, decorators: [{
                type: Inject,
                args: [ChangeDetectorRef]
            }] }, { type: i0.ElementRef }, { type: i1.Overlay }]; }, { id: [{
            type: Input
        }], placeholder: [{
            type: Input
        }], ariaLabel: [{
            type: Input
        }], scrollStrategy: [{
            type: Input
        }], overlayPanelClass: [{
            type: Input
        }], positions: [{
            type: Input
        }], trigger: [{
            type: ViewChild,
            args: ['trigger']
        }], customTrigger: [{
            type: ContentChild,
            args: [TkSelectTrigger]
        }], suffix: [{
            type: ContentChildren,
            args: [TkSuffix]
        }], multiple: [{
            type: Input
        }], open: [{
            type: HostListener,
            args: ['click']
        }], handleKeydown: [{
            type: HostListener,
            args: ['keydown']
        }] }); })();

class TkSelectModule {
}
TkSelectModule.ɵfac = function TkSelectModule_Factory(t) { return new (t || TkSelectModule)(); };
TkSelectModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: TkSelectModule });
TkSelectModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule,
        OverlayModule,
        TkIconModule,
        TkOptionModule,
        TkPanelModule,
        A11yModule, TkOptionModule, TkPanelModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkSelectModule, [{
        type: NgModule,
        args: [{
                imports: [
                    CommonModule,
                    OverlayModule,
                    TkIconModule,
                    TkOptionModule,
                    TkPanelModule,
                    A11yModule,
                ],
                exports: [TkSelect, TkSelectTrigger, TkOptionModule, TkPanelModule],
                declarations: [TkSelect, TkSelectTrigger],
                providers: [],
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(TkSelectModule, { declarations: [TkSelect, TkSelectTrigger], imports: [CommonModule,
        OverlayModule,
        TkIconModule,
        TkOptionModule,
        TkPanelModule,
        A11yModule], exports: [TkSelect, TkSelectTrigger, TkOptionModule, TkPanelModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { TkSelect, TkSelectModule, TkSelectTrigger };

