<button type="button" *ngIf="fileUrl(); else upload" (click)="openEditModal()" class="container">
	<img [src]="fileUrl()" alt="avatar" class="avatar" />
	<tk-icon class="icon" icon="edit" />
</button>

<ng-template #upload>
	<label (change)="uploadFile($event)" class="container">
		<input type="file" />
		<img src="/assets/images/default_avatar.webp" alt="avatar" class="avatar" />
		<tk-icon class="icon" icon="paperclip" />
	</label>
</ng-template>
