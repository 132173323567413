import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'tk-form-field-info',
	standalone: true,
	templateUrl: './form-field-info.component.html',
	styleUrl: './form-field-info.component.sass',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule],
})
export class FormFieldInfoComponent {}
