export const Categories: {
	[key: string]: {
		name: string;
		color: string;
	};
} = {
	HARDSKILL: {
		name: 'Хардскилл',
		color: '#1c55e2',
	},
	STORY: {
		name: 'Истории',
		color: '#5d13bb',
	},
	TRANSLATIONS: {
		name: 'Переводы',
		color: '#e2631c',
	},
	PROCESSES: {
		name: 'Процессы',
		color: '#d11e5e',
	},
	CASES: {
		name: 'Кейсы',
		color: '#00add3',
	},
};
