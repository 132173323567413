import { ObserversModule } from '@angular/cdk/observers';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Directive, Input, Component, ViewEncapsulation, ChangeDetectionStrategy, ContentChild, ContentChildren, ViewChild, HostBinding, NgModule } from '@angular/core';
import { AbstractTkControl } from '@technokratos/components-angular/core';

let nextUniqueId$1 = 0;
class TkError {
    constructor() {
        this.id = `tk-error-${nextUniqueId$1++}`;
    }
}
TkError.ɵfac = function TkError_Factory(t) { return new (t || TkError)(); };
TkError.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: TkError, selectors: [["tk-error"], ["", "tkError", ""]], hostAttrs: ["role", "alert", 1, "tk-error"], hostVars: 1, hostBindings: function TkError_HostBindings(rf, ctx) { if (rf & 2) {
        i0.ɵɵattribute("id", ctx.id);
    } }, inputs: { id: "id" } });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkError, [{
        type: Directive,
        args: [{
                selector: 'tk-error, [tkError]',
                host: {
                    'class': 'tk-error',
                    'role': 'alert',
                    '[attr.id]': 'id',
                },
            }]
    }], null, { id: [{
            type: Input
        }] }); })();

class TkLabel {
}
TkLabel.ɵfac = function TkLabel_Factory(t) { return new (t || TkLabel)(); };
TkLabel.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: TkLabel, selectors: [["tk-label"], ["", "tkLabel", ""]], hostVars: 2, hostBindings: function TkLabel_HostBindings(rf, ctx) { if (rf & 2) {
        i0.ɵɵclassProp("tk-label", true);
    } } });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkLabel, [{
        type: Directive,
        args: [{
                selector: 'tk-label, [tkLabel]',
                host: {
                    '[class.tk-label]': 'true',
                },
            }]
    }], null, null); })();

let nextUniqueId = 0;
class TkHint {
    constructor() {
        this.id = `tk-hint-${nextUniqueId++}`;
    }
}
TkHint.ɵfac = function TkHint_Factory(t) { return new (t || TkHint)(); };
TkHint.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: TkHint, selectors: [["tk-hint"], ["", "tkHint", ""]], hostAttrs: [1, "tk-hint"], hostVars: 1, hostBindings: function TkHint_HostBindings(rf, ctx) { if (rf & 2) {
        i0.ɵɵattribute("id", ctx.id);
    } }, inputs: { id: "id" } });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkHint, [{
        type: Directive,
        args: [{
                selector: 'tk-hint, [tkHint]',
                host: {
                    'class': 'tk-hint',
                    '[attr.id]': 'id',
                },
            }]
    }], null, { id: [{
            type: Input
        }] }); })();

class TkSuffix {
}
TkSuffix.ɵfac = function TkSuffix_Factory(t) { return new (t || TkSuffix)(); };
TkSuffix.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: TkSuffix, selectors: [["tk-suffix"], ["", "tkSuffix", ""]], hostVars: 2, hostBindings: function TkSuffix_HostBindings(rf, ctx) { if (rf & 2) {
        i0.ɵɵclassProp("tk-suffix", true);
    } } });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkSuffix, [{
        type: Directive,
        args: [{
                selector: 'tk-suffix, [tkSuffix]',
                host: {
                    '[class.tk-suffix]': 'true',
                },
            }]
    }], null, null); })();

class TkPrefix {
}
TkPrefix.ɵfac = function TkPrefix_Factory(t) { return new (t || TkPrefix)(); };
TkPrefix.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: TkPrefix, selectors: [["tk-prefix"], ["", "tkPrefix", ""]], hostVars: 2, hostBindings: function TkPrefix_HostBindings(rf, ctx) { if (rf & 2) {
        i0.ɵɵclassProp("tk-prefix", true);
    } } });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkPrefix, [{
        type: Directive,
        args: [{
                selector: 'tk-prefix, [tkPrefix]',
                host: {
                    '[class.tk-prefix]': 'true',
                },
            }]
    }], null, null); })();

const _c0$1 = ["errors"];
const _c1$1 = ["hints"];
function TkFormField_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵprojection(1, 2);
    i0.ɵɵelementEnd();
} }
function TkFormField_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵprojection(1, 3);
    i0.ɵɵelementEnd();
} }
function TkFormField_div_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 10, 11);
    i0.ɵɵprojection(2, 4);
    i0.ɵɵelementEnd();
} }
const _c2 = ["*", [["tk-error"], ["", "tkError", ""], ["", "tkerror", ""]], [["tk-prefix"], ["", "tkPrefix", ""]], [["tk-suffix"], ["", "tkSuffix", ""]], [["tk-hint"], ["", "tkHint", ""], ["", "tkhint", ""]]];
const _c3 = ["*", "tk-error, [tkError], [tkerror]", "tk-prefix, [tkPrefix]", "tk-suffix, [tkSuffix]", "tk-hint, [tkHint], [tkhint]"];
class TkFormField {
    constructor(elementRef, cdr) {
        this.elementRef = elementRef;
        this.cdr = cdr;
        this.size = 'm';
    }
    get labelFloat() {
        return this.control?.labelFloat || false;
    }
    get hasLabel() {
        return Boolean(this.label);
    }
    get showError() {
        return (this.errors?.length > 0 ||
            this.errorsView?.nativeElement?.children?.length > 0);
    }
    get showHint() {
        return !this.showError;
    }
    get hasError() {
        return (this.showError || (this.control?.invalid && this.control?.touched));
    }
    get hasSuffix() {
        return this.suffix.length > 0;
    }
    get hasPrefix() {
        return this.prefix.length > 0;
    }
    get hostClass() {
        return {
            'tk-form-field': true,
            'class.tk-form-field--focused': 'control?.focused',
            'tk-form-field--invalid': this.hasError,
            'tk-form-field--disabled': this.control?.disabled,
            'tk-form-field--has-label': this.hasLabel,
            'tk-form-field--has-value': this.control?.hasValue,
            'tk-form-field--label-float': this.labelFloat,
            [`tk-form-field--size-${this.size}`]: true,
        };
    }
    ngAfterContentInit() {
        this.validateControlChild();
        this.errors.changes.subscribe(() => this.cdr.markForCheck());
        this.hints.changes.subscribe(() => this.cdr.markForCheck());
        this.suffix.changes.subscribe(() => this.cdr.markForCheck());
        this.prefix.changes.subscribe(() => this.cdr.markForCheck());
        if (this.control.controlType) {
            this.elementRef.nativeElement.classList.add(`tk-form-field--type--${this.control.controlType}`);
        }
    }
    ngAfterViewInit() {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach(() => this.cdr.markForCheck());
        });
        observer.observe(this.errorsView.nativeElement, {
            childList: true,
        });
    }
    containerClick() {
        this.control.containerClick();
    }
    validateControlChild() {
        if (!this.control && (typeof ngDevMode === 'undefined' || ngDevMode)) {
            throw Error('Внутри tk-form-field обязательно должен быть AbstractTkControl');
        }
    }
}
TkFormField.ɵfac = function TkFormField_Factory(t) { return new (t || TkFormField)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef)); };
TkFormField.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TkFormField, selectors: [["tk-form-field"]], contentQueries: function TkFormField_ContentQueries(rf, ctx, dirIndex) { if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, AbstractTkControl, 5);
        i0.ɵɵcontentQuery(dirIndex, TkLabel, 5);
        i0.ɵɵcontentQuery(dirIndex, TkError, 5);
        i0.ɵɵcontentQuery(dirIndex, TkHint, 5);
        i0.ɵɵcontentQuery(dirIndex, TkPrefix, 4);
        i0.ɵɵcontentQuery(dirIndex, TkSuffix, 4);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.control = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.label = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.errors = _t);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.hints = _t);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.prefix = _t);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.suffix = _t);
    } }, viewQuery: function TkFormField_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0$1, 5);
        i0.ɵɵviewQuery(_c1$1, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.errorsView = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.hintsView = _t.first);
    } }, hostVars: 2, hostBindings: function TkFormField_HostBindings(rf, ctx) { if (rf & 2) {
        i0.ɵɵclassMap(ctx.hostClass);
    } }, inputs: { size: "size" }, exportAs: ["tkFormField"], ngContentSelectors: _c3, decls: 10, vars: 3, consts: [[1, "tk-form-field__flex", 3, "click"], ["class", "tk-form-field__prefix", 4, "ngIf"], [1, "tk-form-field__infix"], ["class", "tk-form-field__suffix", 4, "ngIf"], [1, "tk-form-field__subscript"], [1, "tk-form-field__errors"], ["errors", ""], ["class", "tk-form-field__hints", 4, "ngIf"], [1, "tk-form-field__prefix"], [1, "tk-form-field__suffix"], [1, "tk-form-field__hints"], ["hints", ""]], template: function TkFormField_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef(_c2);
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵlistener("click", function TkFormField_Template_div_click_0_listener() { return ctx.containerClick(); });
        i0.ɵɵtemplate(1, TkFormField_div_1_Template, 2, 0, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵprojection(3);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(4, TkFormField_div_4_Template, 2, 0, "div", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "div", 4)(6, "div", 5, 6);
        i0.ɵɵprojection(8, 1);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(9, TkFormField_div_9_Template, 3, 0, "div", 7);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.hasPrefix);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.hasSuffix);
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("ngIf", ctx.showHint);
    } }, dependencies: [i1.NgIf], encapsulation: 2, changeDetection: 0 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkFormField, [{
        type: Component,
        args: [{ selector: 'tk-form-field', exportAs: 'tkFormField', encapsulation: ViewEncapsulation.None, changeDetection: ChangeDetectionStrategy.OnPush, template: "<div class=\"tk-form-field__flex\" (click)=\"containerClick()\">\n\t<div class=\"tk-form-field__prefix\" *ngIf=\"hasPrefix\">\n\t\t<ng-content select=\"tk-prefix, [tkPrefix]\"></ng-content>\n\t</div>\n\t<div class=\"tk-form-field__infix\">\n\t\t<ng-content></ng-content>\n\t</div>\n\t<div class=\"tk-form-field__suffix\" *ngIf=\"hasSuffix\">\n\t\t<ng-content select=\"tk-suffix, [tkSuffix]\"></ng-content>\n\t</div>\n</div>\n\n<div class=\"tk-form-field__subscript\">\n\t<div #errors class=\"tk-form-field__errors\">\n\t\t<ng-content select=\"tk-error, [tkError], [tkerror]\"></ng-content>\n\t</div>\n\t<div *ngIf=\"showHint\" #hints class=\"tk-form-field__hints\">\n\t\t<ng-content select=\"tk-hint, [tkHint], [tkhint]\"></ng-content>\n\t</div>\n</div>\n" }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.ChangeDetectorRef }]; }, { size: [{
            type: Input
        }], control: [{
            type: ContentChild,
            args: [AbstractTkControl]
        }], label: [{
            type: ContentChild,
            args: [TkLabel]
        }], errors: [{
            type: ContentChildren,
            args: [TkError, { descendants: true }]
        }], hints: [{
            type: ContentChildren,
            args: [TkHint, { descendants: true }]
        }], errorsView: [{
            type: ViewChild,
            args: ['errors']
        }], hintsView: [{
            type: ViewChild,
            args: ['hints']
        }], prefix: [{
            type: ContentChildren,
            args: [TkPrefix]
        }], suffix: [{
            type: ContentChildren,
            args: [TkSuffix]
        }], hostClass: [{
            type: HostBinding,
            args: ['class']
        }] }); })();

function TkInputWrapper_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵprojection(1, 1);
    i0.ɵɵelementEnd();
} }
function TkInputWrapper_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵprojection(1, 2);
    i0.ɵɵelementEnd();
} }
function TkInputWrapper_div_5_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵprojection(1, 3);
    i0.ɵɵelementContainerEnd();
} }
function TkInputWrapper_div_5_ng_container_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵprojection(1, 4);
    i0.ɵɵelementContainerEnd();
} }
function TkInputWrapper_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵtemplate(1, TkInputWrapper_div_5_ng_container_1_Template, 2, 0, "ng-container", 8);
    i0.ɵɵtemplate(2, TkInputWrapper_div_5_ng_container_2_Template, 2, 0, "ng-container", 8);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r2.showError);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r2.showHint);
} }
const _c0 = ["*", [["tk-prefix"], ["", "tkPrefix", ""]], [["tk-suffix"], ["", "tkSuffix", ""]], [["tk-error"], ["", "tkError", ""], ["", "tkerror", ""]], [["tk-hint"], ["", "tkHint", ""], ["", "tkhint", ""]]];
const _c1 = ["*", "tk-prefix, [tkPrefix]", "tk-suffix, [tkSuffix]", "tk-error, [tkError], [tkerror]", "tk-hint, [tkHint], [tkhint]"];
class TkInputWrapper {
    constructor(elementRef, cdr) {
        this.elementRef = elementRef;
        this.cdr = cdr;
    }
    get labelFloat() {
        return this.control?.labelFloat || false;
    }
    get showError() {
        return this.errors.length > 0;
    }
    get showHint() {
        return this.hints.length > 0 && !this.showError;
    }
    get hasError() {
        return (this.errors.length > 0 ||
            (this.control?.invalid && this.control?.touched));
    }
    get hasSuffix() {
        return this.suffix.length > 0;
    }
    get hasPrefix() {
        return this.prefix.length > 0;
    }
    ngAfterContentInit() {
        this.validateControlChild();
        this.errors.changes.subscribe(() => this.cdr.markForCheck());
        this.hints.changes.subscribe(() => this.cdr.markForCheck());
        this.suffix.changes.subscribe(() => this.cdr.markForCheck());
        this.prefix.changes.subscribe(() => this.cdr.markForCheck());
        if (this.control.controlType) {
            this.elementRef.nativeElement.classList.add(`tk-form-field--type--${this.control.controlType}`);
        }
    }
    validateControlChild() {
        if (!this.control && (typeof ngDevMode === 'undefined' || ngDevMode)) {
            throw Error('Внутри tk-form-field обязательно должен быть AbstractTkControl');
        }
    }
    containerClick() {
        this.control.containerClick();
    }
}
TkInputWrapper.ɵfac = function TkInputWrapper_Factory(t) { return new (t || TkInputWrapper)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef)); };
TkInputWrapper.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TkInputWrapper, selectors: [["tk-input-wrapper"]], contentQueries: function TkInputWrapper_ContentQueries(rf, ctx, dirIndex) { if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, AbstractTkControl, 5);
        i0.ɵɵcontentQuery(dirIndex, TkError, 5);
        i0.ɵɵcontentQuery(dirIndex, TkHint, 5);
        i0.ɵɵcontentQuery(dirIndex, TkPrefix, 5);
        i0.ɵɵcontentQuery(dirIndex, TkSuffix, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.control = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.errors = _t);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.hints = _t);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.prefix = _t);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.suffix = _t);
    } }, hostVars: 10, hostBindings: function TkInputWrapper_HostBindings(rf, ctx) { if (rf & 2) {
        i0.ɵɵclassProp("tk-input-wrapper", true)("tk-input-wrapper--focused", ctx.control == null ? null : ctx.control.focused)("tk-input-wrapper--invalid", ctx.hasError)("tk-input-wrapper--disabled", ctx.control == null ? null : ctx.control.disabled)("tk-input-wrapper--has-value", ctx.control == null ? null : ctx.control.hasValue);
    } }, exportAs: ["tkInputWrapper"], ngContentSelectors: _c1, decls: 6, vars: 3, consts: [[1, "tk-input-wrapper__flex"], ["class", "tk-input-wrapper__prefix", 4, "ngIf"], [1, "tk-input-wrapper__infix"], ["class", "tk-input-wrapper__suffix", 4, "ngIf"], ["class", "tk-input-wrapper__subscript", 4, "ngIf"], [1, "tk-input-wrapper__prefix"], [1, "tk-input-wrapper__suffix"], [1, "tk-input-wrapper__subscript"], [4, "ngIf"]], template: function TkInputWrapper_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef(_c0);
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, TkInputWrapper_div_1_Template, 2, 0, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵprojection(3);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(4, TkInputWrapper_div_4_Template, 2, 0, "div", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(5, TkInputWrapper_div_5_Template, 3, 2, "div", 4);
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.hasPrefix);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.hasSuffix);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.showError || ctx.showHint);
    } }, dependencies: [i1.NgIf], encapsulation: 2, changeDetection: 0 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkInputWrapper, [{
        type: Component,
        args: [{ selector: 'tk-input-wrapper', exportAs: 'tkInputWrapper', encapsulation: ViewEncapsulation.None, host: {
                    '[class.tk-input-wrapper]': 'true',
                    '[class.tk-input-wrapper--focused]': 'control?.focused',
                    '[class.tk-input-wrapper--invalid]': 'hasError',
                    '[class.tk-input-wrapper--disabled]': 'control?.disabled',
                    '[class.tk-input-wrapper--has-value]': 'control?.hasValue',
                }, changeDetection: ChangeDetectionStrategy.OnPush, template: "<div class=\"tk-input-wrapper__flex\">\n\t<div class=\"tk-input-wrapper__prefix\" *ngIf=\"hasPrefix\">\n\t\t<ng-content select=\"tk-prefix, [tkPrefix]\"></ng-content>\n\t</div>\n\t<div class=\"tk-input-wrapper__infix\">\n\t\t<ng-content></ng-content>\n\t</div>\n\t<div class=\"tk-input-wrapper__suffix\" *ngIf=\"hasSuffix\">\n\t\t<ng-content select=\"tk-suffix, [tkSuffix]\"></ng-content>\n\t</div>\n</div>\n<div class=\"tk-input-wrapper__subscript\" *ngIf=\"showError || showHint\">\n\t<ng-container *ngIf=\"showError\">\n\t\t<ng-content select=\"tk-error, [tkError], [tkerror]\"></ng-content>\n\t</ng-container>\n\t<ng-container *ngIf=\"showHint\">\n\t\t<ng-content select=\"tk-hint, [tkHint], [tkhint]\"></ng-content>\n\t</ng-container>\n</div>\n" }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.ChangeDetectorRef }]; }, { control: [{
            type: ContentChild,
            args: [AbstractTkControl]
        }], errors: [{
            type: ContentChildren,
            args: [TkError, { descendants: true }]
        }], hints: [{
            type: ContentChildren,
            args: [TkHint, { descendants: true }]
        }], prefix: [{
            type: ContentChildren,
            args: [TkPrefix, { descendants: true }]
        }], suffix: [{
            type: ContentChildren,
            args: [TkSuffix, { descendants: true }]
        }] }); })();

class TkFormFieldModule {
}
TkFormFieldModule.ɵfac = function TkFormFieldModule_Factory(t) { return new (t || TkFormFieldModule)(); };
TkFormFieldModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: TkFormFieldModule });
TkFormFieldModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule, ObserversModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkFormFieldModule, [{
        type: NgModule,
        args: [{
                declarations: [
                    TkError,
                    TkFormField,
                    TkHint,
                    TkLabel,
                    TkPrefix,
                    TkSuffix,
                    TkInputWrapper,
                ],
                imports: [CommonModule, ObserversModule],
                exports: [
                    TkError,
                    TkFormField,
                    TkHint,
                    TkLabel,
                    TkPrefix,
                    TkSuffix,
                    TkInputWrapper,
                ],
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(TkFormFieldModule, { declarations: [TkError,
        TkFormField,
        TkHint,
        TkLabel,
        TkPrefix,
        TkSuffix,
        TkInputWrapper], imports: [CommonModule, ObserversModule], exports: [TkError,
        TkFormField,
        TkHint,
        TkLabel,
        TkPrefix,
        TkSuffix,
        TkInputWrapper] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { TkError, TkFormField, TkFormFieldModule, TkHint, TkInputWrapper, TkLabel, TkPrefix, TkSuffix };

