import { Component, forwardRef, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'tk-checkbox-control',
	templateUrl: './checkbox-form-control.component.html',
	styleUrls: ['./checkbox-form-control.component.sass'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => CheckboxFormControlComponent),
		},
	],
})
export class CheckboxFormControlComponent implements ControlValueAccessor {
	@Input() type: 'primary' | 'secondary' = 'primary';

	value = false;

	@HostBinding('class') get hostClass(): string {
		return `checkbox__${this.type}`;
	}

	onChange = (checked: boolean) => {};
	onTouched = () => {};

	handleOnChange(event: Event): void {
		const newValue: boolean = (event.target as HTMLInputElement).checked;
		this.writeValue(newValue);

		this.onTouched();
		this.onChange(this.value);
	}

	writeValue(flag: boolean): void {
		this.value = flag;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
}
