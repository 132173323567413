import { BlockTool, BlockToolData } from '@editorjs/editorjs';
import { IncutToolData } from '@models/editor-components';

export class IncutTool implements BlockTool {
	data: IncutToolData;

	constructor({ data }: BlockToolData) {
		this.data = data;
	}
	static get toolbox() {
		return {
			title: 'Incut',
			icon:
				'<svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-layout-text-sidebar-reverse">\n' +
				'  <path d="M12.5 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm0 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm.5 3.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5zm-.5 2.5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5z"/>\n' +
				'  <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2zM4 1v14H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h2zm1 0h9a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5V1z"/>\n' +
				'</svg>',
		};
	}
	render() {
		const input = document.createElement('div');
		input.classList.add('incut-tool');
		input.contentEditable = 'true';
		input.innerHTML = this.data.text || '';
		return input;
	}

	save(blockContent: HTMLInputElement) {
		this.data = {
			text: blockContent.innerHTML,
		};
		return this.data;
	}
}
