import * as i0 from '@angular/core';
import { InjectionToken, EventEmitter, Component, ChangeDetectionStrategy, ViewEncapsulation, Optional, Inject, Input, ContentChildren, Output, HostListener, Directive, NgModule } from '@angular/core';
import * as i3 from '@angular/forms';
import { noop } from '@technokratos/components-angular/core';
import { Subject, merge } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { TkSuffix } from '@technokratos/components-angular/form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@technokratos/components-angular/icon';
import { TkIconModule } from '@technokratos/components-angular/icon';

const _c0 = ["value", ""];
function TkCheckbox_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵprojection(1, 1);
    i0.ɵɵelementEnd();
} }
const _c1 = ["*", [["tk-suffix"], ["", "tkSuffix", ""]]];
const _c2 = ["*", "tk-suffix, [tkSuffix]"];
const _c3 = ["formControlName", ""];
function TkStandaloneCheckbox_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵprojection(1, 1);
    i0.ɵɵelementEnd();
} }
const TK_CHECKBOX_GROUP = new InjectionToken('TkCheckboxGroup');
class TkCheckbox {
    constructor(cdr, group) {
        this.cdr = cdr;
        this.group = group;
        this.itemClick = new EventEmitter();
        this._checked = false;
        this._disabled = false;
        this._indeterminate = false;
        this._theme = 'inline';
        this._reverse = false;
    }
    get checked() {
        return this._checked;
    }
    set checked(value) {
        this._checked = coerceBooleanProperty(value);
    }
    get disabled() {
        return this._disabled;
    }
    set disabled(value) {
        this._disabled = coerceBooleanProperty(value);
    }
    get indeterminate() {
        return this._indeterminate;
    }
    set indeterminate(value) {
        this._indeterminate = coerceBooleanProperty(value);
    }
    get theme() {
        return this.group.theme || this._theme;
    }
    set theme(value) {
        this._theme = value;
    }
    get reverse() {
        return this.group.reverse || this._reverse;
    }
    set reverse(value) {
        this._reverse = coerceBooleanProperty(value);
    }
    get hasSuffix() {
        return this.suffix.length > 0;
    }
    ngOnInit() {
        this.group?.register(this);
    }
    ngAfterContentInit() {
        this.suffix.changes.subscribe(() => this.cdr.markForCheck());
    }
    ngOnDestroy() {
        this.group?.deregister(this);
    }
    toggle() {
        this.checked = !this.checked;
        this.itemClick.emit(this);
    }
    select() {
        if (!this.checked) {
            this.checked = true;
            this.cdr.markForCheck();
        }
    }
    deselect() {
        if (this.checked) {
            this.checked = false;
            this.cdr.markForCheck();
        }
    }
}
TkCheckbox.ɵfac = function TkCheckbox_Factory(t) { return new (t || TkCheckbox)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(TK_CHECKBOX_GROUP, 8)); };
TkCheckbox.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TkCheckbox, selectors: [["tk-checkbox", "value", ""]], contentQueries: function TkCheckbox_ContentQueries(rf, ctx, dirIndex) { if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, TkSuffix, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.suffix = _t);
    } }, hostAttrs: ["role", "checkbox", 1, "tk-checkbox"], hostVars: 11, hostBindings: function TkCheckbox_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("click", function TkCheckbox_click_HostBindingHandler() { return ctx.toggle(); });
    } if (rf & 2) {
        i0.ɵɵattribute("tabindex", -1);
        i0.ɵɵclassProp("tk-checkbox--checked", ctx.checked)("tk-checkbox--indeterminate", ctx.indeterminate)("tk-checkbox--disabled", ctx.disabled)("tk-checkbox--inline", ctx.theme === "inline")("tk-checkbox--filled", ctx.theme === "filled");
    } }, inputs: { value: "value", checked: "checked", disabled: "disabled", indeterminate: "indeterminate", theme: "theme", reverse: "reverse" }, outputs: { itemClick: "itemClick" }, exportAs: ["tkCheckbox"], attrs: _c0, ngContentSelectors: _c2, decls: 6, vars: 6, consts: [[1, "tk-checkbox__wrapper"], [1, "tk-checkbox__box"], [1, "tk-checkbox__icon", 3, "icon"], [1, "tk-checkbox__children"], ["class", "tk-checkbox__suffix", 4, "ngIf"], [1, "tk-checkbox__suffix"]], template: function TkCheckbox_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef(_c1);
        i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
        i0.ɵɵelement(2, "tk-icon", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵprojection(4);
        i0.ɵɵelementEnd()();
        i0.ɵɵtemplate(5, TkCheckbox_div_5_Template, 2, 0, "div", 4);
    } if (rf & 2) {
        i0.ɵɵclassProp("tk-checkbox__wrapper--reverse", ctx.reverse)("tk-checkbox__wrapper--not-reverse", !ctx.reverse);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("icon", ctx.indeterminate ? "tkCheckIndeterminate" : "tkCheck");
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.hasSuffix);
    } }, dependencies: [i1.NgIf, i2.TkIcon], encapsulation: 2, changeDetection: 0 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkCheckbox, [{
        type: Component,
        args: [{ selector: 'tk-checkbox[value]', changeDetection: ChangeDetectionStrategy.OnPush, exportAs: 'tkCheckbox', encapsulation: ViewEncapsulation.None, host: {
                    'class': 'tk-checkbox',
                    '[class.tk-checkbox--checked]': 'checked',
                    '[class.tk-checkbox--indeterminate]': 'indeterminate',
                    '[class.tk-checkbox--disabled]': 'disabled',
                    '[class.tk-checkbox--inline]': 'theme === "inline"',
                    '[class.tk-checkbox--filled]': 'theme === "filled"',
                    '[attr.tabindex]': '-1',
                    'role': 'checkbox',
                }, template: "<div\n\tclass=\"tk-checkbox__wrapper\"\n\t[class.tk-checkbox__wrapper--reverse]=\"reverse\"\n\t[class.tk-checkbox__wrapper--not-reverse]=\"!reverse\"\n>\n\t<div class=\"tk-checkbox__box\">\n\t\t<tk-icon\n\t\t\tclass=\"tk-checkbox__icon\"\n\t\t\t[icon]=\"indeterminate ? 'tkCheckIndeterminate' : 'tkCheck' \"\n\t\t></tk-icon>\n\t</div>\n\t<div class=\"tk-checkbox__children\">\n\t\t<ng-content></ng-content>\n\t</div>\n</div>\n<div class=\"tk-checkbox__suffix\" *ngIf=\"hasSuffix\">\n\t<ng-content select=\"tk-suffix, [tkSuffix]\"></ng-content>\n</div>\n" }]
    }], function () { return [{ type: i0.ChangeDetectorRef }, { type: undefined, decorators: [{
                type: Optional
            }, {
                type: Inject,
                args: [TK_CHECKBOX_GROUP]
            }] }]; }, { value: [{
            type: Input
        }], suffix: [{
            type: ContentChildren,
            args: [TkSuffix, { descendants: true }]
        }], itemClick: [{
            type: Output
        }], checked: [{
            type: Input
        }], disabled: [{
            type: Input
        }], indeterminate: [{
            type: Input
        }], theme: [{
            type: Input
        }], reverse: [{
            type: Input
        }], toggle: [{
            type: HostListener,
            args: ['click']
        }] }); })();
class TkStandaloneCheckbox {
    constructor(cdr, _parentForm, _parentFormGroup, ngControl, checkboxGroup) {
        this.cdr = cdr;
        this.ngControl = ngControl;
        this.checkboxGroup = checkboxGroup;
        this.theme = 'inline';
        this.indeterminateChange = new EventEmitter();
        this.id = '';
        this._checked = false;
        this._disabled = false;
        this._indeterminate = false;
        this._reverse = false;
        this._disableClick = false;
        this.onTouched = () => { };
        this.onChange = () => { };
        if (ngControl) {
            ngControl.valueAccessor = this;
        }
    }
    get checked() {
        return this._checked;
    }
    set checked(value) {
        this._checked = coerceBooleanProperty(value);
    }
    get disabled() {
        return this._disabled;
    }
    set disabled(value) {
        this._disabled = coerceBooleanProperty(value);
    }
    get indeterminate() {
        return this._indeterminate;
    }
    set indeterminate(value) {
        this._indeterminate = coerceBooleanProperty(value);
    }
    get reverse() {
        return this._reverse;
    }
    set reverse(value) {
        this._reverse = coerceBooleanProperty(value);
    }
    get disableClick() {
        return this._disableClick;
    }
    set disableClick(value) {
        this._disableClick = coerceBooleanProperty(value);
    }
    get hasSuffix() {
        return this.suffix.length > 0;
    }
    setDisabledState(isDisabled) {
        this.disabled = isDisabled;
        this.cdr.markForCheck();
    }
    registerOnChange(fn) {
        this.onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    writeValue(value) {
        this.checked = value;
        this.cdr.markForCheck();
    }
    toggle() {
        if (this.disableClick) {
            return;
        }
        if (this.indeterminate) {
            this.checked = false;
            this.indeterminate = false;
            this.indeterminateChange.emit(this.indeterminate);
        }
        else {
            this.checked = !this.checked;
        }
        this.onChange(this.checked);
    }
}
TkStandaloneCheckbox.ɵfac = function TkStandaloneCheckbox_Factory(t) { return new (t || TkStandaloneCheckbox)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i3.NgForm, 8), i0.ɵɵdirectiveInject(i3.FormGroupDirective, 8), i0.ɵɵdirectiveInject(i3.NgControl, 8), i0.ɵɵdirectiveInject(TK_CHECKBOX_GROUP, 8)); };
TkStandaloneCheckbox.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TkStandaloneCheckbox, selectors: [["tk-checkbox", "formControlName", ""], ["tk-checkbox", "formControl", ""], ["tk-checkbox", "ngModel", ""]], contentQueries: function TkStandaloneCheckbox_ContentQueries(rf, ctx, dirIndex) { if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, TkSuffix, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.suffix = _t);
    } }, hostAttrs: ["role", "checkbox", 1, "tk-checkbox"], hostVars: 15, hostBindings: function TkStandaloneCheckbox_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("click", function TkStandaloneCheckbox_click_HostBindingHandler() { return ctx.toggle(); });
    } if (rf & 2) {
        i0.ɵɵattribute("tabindex", -1);
        i0.ɵɵclassProp("tk-checkbox--reverse", ctx.reverse)("tk-checkbox--not-reverse", !ctx.reverse)("tk-checkbox--checked", ctx.checked)("tk-checkbox--indeterminate", ctx.indeterminate)("tk-checkbox--disabled", ctx.disabled)("tk-checkbox--inline", ctx.theme === "inline")("tk-checkbox--filled", ctx.theme === "filled");
    } }, inputs: { theme: "theme", checked: "checked", disabled: "disabled", indeterminate: "indeterminate", reverse: "reverse", disableClick: "disableClick" }, outputs: { indeterminateChange: "indeterminateChange" }, exportAs: ["tkCheckbox"], attrs: _c3, ngContentSelectors: _c2, decls: 6, vars: 6, consts: [[1, "tk-checkbox__wrapper"], [1, "tk-checkbox__box"], [1, "tk-checkbox__icon", 3, "icon"], [1, "tk-checkbox__children"], ["class", "tk-checkbox__suffix", 4, "ngIf"], [1, "tk-checkbox__suffix"]], template: function TkStandaloneCheckbox_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef(_c1);
        i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
        i0.ɵɵelement(2, "tk-icon", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵprojection(4);
        i0.ɵɵelementEnd()();
        i0.ɵɵtemplate(5, TkStandaloneCheckbox_div_5_Template, 2, 0, "div", 4);
    } if (rf & 2) {
        i0.ɵɵclassProp("tk-checkbox__wrapper--reverse", ctx.reverse)("tk-checkbox__wrapper--not-reverse", !ctx.reverse);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("icon", ctx.indeterminate ? "tkCheckIndeterminate" : "tkCheck");
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.hasSuffix);
    } }, dependencies: [i1.NgIf, i2.TkIcon], encapsulation: 2, changeDetection: 0 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkStandaloneCheckbox, [{
        type: Component,
        args: [{ selector: 'tk-checkbox[formControlName], tk-checkbox[formControl], tk-checkbox[ngModel]', changeDetection: ChangeDetectionStrategy.OnPush, exportAs: 'tkCheckbox', encapsulation: ViewEncapsulation.None, host: {
                    'class': 'tk-checkbox',
                    '[class.tk-checkbox--reverse]': 'reverse',
                    '[class.tk-checkbox--not-reverse]': '!reverse',
                    '[class.tk-checkbox--checked]': 'checked',
                    '[class.tk-checkbox--indeterminate]': 'indeterminate',
                    '[class.tk-checkbox--disabled]': 'disabled',
                    '[class.tk-checkbox--inline]': 'theme === "inline"',
                    '[class.tk-checkbox--filled]': 'theme === "filled"',
                    '[attr.tabindex]': '-1',
                    'role': 'checkbox',
                }, template: "<div\n\tclass=\"tk-checkbox__wrapper\"\n\t[class.tk-checkbox__wrapper--reverse]=\"reverse\"\n\t[class.tk-checkbox__wrapper--not-reverse]=\"!reverse\"\n>\n\t<div class=\"tk-checkbox__box\">\n\t\t<tk-icon\n\t\t\tclass=\"tk-checkbox__icon\"\n\t\t\t[icon]=\"indeterminate ? 'tkCheckIndeterminate' : 'tkCheck' \"\n\t\t></tk-icon>\n\t</div>\n\t<div class=\"tk-checkbox__children\">\n\t\t<ng-content></ng-content>\n\t</div>\n</div>\n<div class=\"tk-checkbox__suffix\" *ngIf=\"hasSuffix\">\n\t<ng-content select=\"tk-suffix, [tkSuffix]\"></ng-content>\n</div>\n" }]
    }], function () { return [{ type: i0.ChangeDetectorRef }, { type: i3.NgForm, decorators: [{
                type: Optional
            }] }, { type: i3.FormGroupDirective, decorators: [{
                type: Optional
            }] }, { type: i3.NgControl, decorators: [{
                type: Optional
            }] }, { type: TkCheckboxGroup, decorators: [{
                type: Optional
            }, {
                type: Inject,
                args: [TK_CHECKBOX_GROUP]
            }] }]; }, { theme: [{
            type: Input
        }], indeterminateChange: [{
            type: Output
        }], suffix: [{
            type: ContentChildren,
            args: [TkSuffix, { descendants: true }]
        }], checked: [{
            type: Input
        }], disabled: [{
            type: Input
        }], indeterminate: [{
            type: Input
        }], reverse: [{
            type: Input
        }], disableClick: [{
            type: Input
        }], toggle: [{
            type: HostListener,
            args: ['click']
        }] }); })();
class TkCheckboxGroup {
    constructor(cdr, ngControl) {
        this.cdr = cdr;
        this.ngControl = ngControl;
        this.theme = 'inline';
        this.direction = 'vertical';
        this.options = [];
        this.options$ = new Subject();
        this.value = [];
        this.destroy$ = new Subject();
        this.onTouchedCallback = noop;
        this.onChangeCallback = noop;
        this._reverse = false;
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
        this.options$
            .pipe(startWith(this.options), switchMap(() => merge(...this.options.map(option => option.itemClick))), takeUntil(this.destroy$))
            .subscribe(event => {
            this._onSelect(event);
        });
    }
    get reverse() {
        return this._reverse;
    }
    set reverse(value) {
        this._reverse = coerceBooleanProperty(value);
    }
    register(checkbox) {
        this.options = [...this.options, checkbox];
        this.options$.next(this.options);
        if (this.value?.includes(checkbox.value)) {
            checkbox.select();
        }
    }
    deregister(checkbox) {
        this.options = this.options.filter(o => o !== checkbox);
        this.options$.next(this.options);
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
    registerOnChange(fn) {
        this.onChangeCallback = fn;
    }
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    writeValue(value) {
        this.value = Array.isArray(value) ? value : [];
        this.updateOptionsFromValue();
        this.cdr.markForCheck();
    }
    updateOptionsFromValue() {
        this.options.forEach(option => this.isSelected(option) ? option.select() : option.deselect());
    }
    _onSelect(option) {
        const wasSelected = this.isSelected(option);
        if (wasSelected !== option.checked) {
            option.checked ? this.select(option) : this.deselect(option);
        }
        if (wasSelected !== this.isSelected(option)) {
            this.propagateChanges();
        }
    }
    isSelected(option) {
        return this.value.includes(option.value);
    }
    select(option) {
        this.value = [...this.value, option.value];
        this.cdr.markForCheck();
    }
    deselect(option) {
        this.value = this.value.filter(v => v !== option.value);
        this.cdr.markForCheck();
    }
    propagateChanges() {
        this.onChangeCallback(this.value);
        this.cdr.markForCheck();
    }
}
TkCheckboxGroup.ɵfac = function TkCheckboxGroup_Factory(t) { return new (t || TkCheckboxGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i3.NgControl, 8)); };
TkCheckboxGroup.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: TkCheckboxGroup, selectors: [["tk-checkbox-group"]], hostVars: 10, hostBindings: function TkCheckboxGroup_HostBindings(rf, ctx) { if (rf & 2) {
        i0.ɵɵclassProp("tk-checkbox-group", true)("tk-checkbox-group--inline", ctx.theme === "inline")("tk-checkbox-group--filled", ctx.theme === "filled")("tk-checkbox-group--vertical", ctx.direction === "vertical")("tk-checkbox-group--horizontal", ctx.direction === "horizontal");
    } }, inputs: { theme: "theme", direction: "direction", reverse: "reverse" }, exportAs: ["tkCheckboxGroup"], features: [i0.ɵɵProvidersFeature([{ provide: TK_CHECKBOX_GROUP, useExisting: TkCheckboxGroup }])] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkCheckboxGroup, [{
        type: Directive,
        args: [{
                selector: 'tk-checkbox-group',
                exportAs: 'tkCheckboxGroup',
                providers: [{ provide: TK_CHECKBOX_GROUP, useExisting: TkCheckboxGroup }],
                host: {
                    '[class.tk-checkbox-group]': 'true',
                    '[class.tk-checkbox-group--inline]': 'theme === "inline"',
                    '[class.tk-checkbox-group--filled]': 'theme === "filled"',
                    '[class.tk-checkbox-group--vertical]': 'direction === "vertical"',
                    '[class.tk-checkbox-group--horizontal]': 'direction === "horizontal"',
                },
            }]
    }], function () { return [{ type: i0.ChangeDetectorRef }, { type: i3.NgControl, decorators: [{
                type: Optional
            }] }]; }, { theme: [{
            type: Input
        }], direction: [{
            type: Input
        }], reverse: [{
            type: Input
        }] }); })();

class TkCheckboxModule {
}
TkCheckboxModule.ɵfac = function TkCheckboxModule_Factory(t) { return new (t || TkCheckboxModule)(); };
TkCheckboxModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: TkCheckboxModule });
TkCheckboxModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule, TkIconModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkCheckboxModule, [{
        type: NgModule,
        args: [{
                declarations: [TkCheckbox, TkCheckboxGroup, TkStandaloneCheckbox],
                exports: [TkCheckbox, TkCheckboxGroup, TkStandaloneCheckbox],
                imports: [CommonModule, TkIconModule],
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(TkCheckboxModule, { declarations: [TkCheckbox, TkCheckboxGroup, TkStandaloneCheckbox], imports: [CommonModule, TkIconModule], exports: [TkCheckbox, TkCheckboxGroup, TkStandaloneCheckbox] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { TK_CHECKBOX_GROUP, TkCheckbox, TkCheckboxGroup, TkCheckboxModule, TkStandaloneCheckbox };

