/**
 *
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TypeEnum = 'ARTICLE_COVER' | 'ARTICLE_IMAGE' | 'AUTHOR_AVATAR';

export const TypeEnum = {
	ARTICLECOVER: 'ARTICLE_COVER' as TypeEnum,
	ARTICLEIMAGE: 'ARTICLE_IMAGE' as TypeEnum,
	AUTHORAVATAR: 'AUTHOR_AVATAR' as TypeEnum,
};
