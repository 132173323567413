import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppModule } from '@technokratos-admin/app.module';
import { RouterOutlet } from '@angular/router';

@Component({
	selector: 'tk-admin-layout',
	standalone: true,
	imports: [CommonModule, AppModule, RouterOutlet],
	templateUrl: './admin-layout.component.html',
	styleUrl: './admin-layout.component.sass',
})
export class AdminLayoutComponent {}
