import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, NgModule } from '@angular/core';

const _c0 = ["*"];
class TkPanel {
}
TkPanel.ɵfac = function TkPanel_Factory(t) { return new (t || TkPanel)(); };
TkPanel.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TkPanel, selectors: [["tk-panel"], ["", "tkPanel", ""]], hostVars: 2, hostBindings: function TkPanel_HostBindings(rf, ctx) { if (rf & 2) {
        i0.ɵɵclassProp("tk-panel", true);
    } }, exportAs: ["tkPanel"], ngContentSelectors: _c0, decls: 1, vars: 0, template: function TkPanel_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkPanel, [{
        type: Component,
        args: [{
                selector: 'tk-panel, [tkPanel]',
                template: '<ng-content></ng-content>',
                exportAs: 'tkPanel',
                host: {
                    '[class.tk-panel]': 'true',
                },
                encapsulation: ViewEncapsulation.None,
                changeDetection: ChangeDetectionStrategy.OnPush,
            }]
    }], null, null); })();

class TkPanelModule {
}
TkPanelModule.ɵfac = function TkPanelModule_Factory(t) { return new (t || TkPanelModule)(); };
TkPanelModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: TkPanelModule });
TkPanelModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkPanelModule, [{
        type: NgModule,
        args: [{
                imports: [CommonModule],
                exports: [TkPanel],
                declarations: [TkPanel],
                providers: [],
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(TkPanelModule, { declarations: [TkPanel], imports: [CommonModule], exports: [TkPanel] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { TkPanel, TkPanelModule };

