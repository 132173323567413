<img
	[alt]="alt"
	[attr.fetchpriority]="priority ? 'high' : 'low'"
	[attr.height]="height || 'auto'"
	[attr.loading]="priority ? 'eager' : 'lazy'"
	[attr.width]="width || 'auto'"
	[ngClass]="{ 'lazy-image': true, 'lazy-image--fill': fill }"
	[src]="pathToImage"
	draggable="false"
/>
