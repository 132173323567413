import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ViewEncapsulation, Optional, Inject, Input, ContentChildren, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeOptions } from '@technokratos/components-angular/theme';
import { TkIcon } from '@technokratos/components-angular/icon';
import { startWith } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

const _c0$1 = ["*"];
class ButtonComponent {
    constructor(elementRef, themeOptions) {
        this.elementRef = elementRef;
        this.themeOptions = themeOptions;
        this.size = 'm';
        this.theme = 'primary';
        this._inline = false;
        this._stretch = false;
    }
    get inline() {
        return this._inline;
    }
    set inline(value) {
        this._inline = coerceBooleanProperty(value);
    }
    get stretch() {
        return this._stretch;
    }
    set stretch(value) {
        this._stretch = coerceBooleanProperty(value);
    }
    get inverted() {
        return this.themeOptions?.inverted;
    }
    ngAfterContentInit() {
        const parent = this.elementRef.nativeElement;
        const nodesLength = parent.childNodes.length;
        this.icons.changes.pipe(startWith(this.icons)).subscribe(() => {
            this.icons.forEach(icon => {
                const el = icon.elementRef.nativeElement;
                el.classList.remove('tk-button__icon--last', 'tk-button__icon--first');
                el.classList.add('tk-button__icon');
                if (nodesLength === 1) {
                    parent.classList.add('tk-button--icon');
                    return;
                }
                if (parent.lastChild === el) {
                    el.classList.add('tk-button__icon--last');
                    return;
                }
                if (parent.firstChild === el) {
                    el.classList.add('tk-button__icon--first');
                }
            });
        });
    }
}
ButtonComponent.ɵfac = function ButtonComponent_Factory(t) { return new (t || ButtonComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(ThemeOptions, 8)); };
ButtonComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ButtonComponent, selectors: [["tk-button"], ["", "tkButton", ""]], contentQueries: function ButtonComponent_ContentQueries(rf, ctx, dirIndex) { if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, TkIcon, 4);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.icons = _t);
    } }, hostVars: 30, hostBindings: function ButtonComponent_HostBindings(rf, ctx) { if (rf & 2) {
        i0.ɵɵclassProp("tk-button", true)("tk-button--size-xs", ctx.size === "xs")("tk-button--size-s", ctx.size === "s")("tk-button--size-m", ctx.size === "m")("tk-button--size-l", ctx.size === "l")("tk-button--size-xl", ctx.size === "xl")("tk-button--theme-primary", ctx.theme === "primary")("tk-button--theme-secondary", ctx.theme === "secondary")("tk-button--theme-ghost", ctx.theme === "ghost")("tk-button--theme-raised", ctx.theme === "raised")("tk-button--theme-inline", ctx.theme === "inline")("tk-button--theme-link", ctx.theme === "link")("tk-button--theme-dashed", ctx.theme === "dashed")("tk-button--non-stretch", !ctx.stretch)("tk-button--stretch", ctx.stretch);
    } }, inputs: { size: "size", theme: "theme", inline: "inline", stretch: "stretch" }, ngContentSelectors: _c0$1, decls: 1, vars: 0, template: function ButtonComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonComponent, [{
        type: Component,
        args: [{ selector: 'tk-button, [tkButton]', changeDetection: ChangeDetectionStrategy.OnPush, encapsulation: ViewEncapsulation.None, host: {
                    '[class.tk-button]': 'true',
                    '[class.tk-button--size-xs]': 'size === "xs"',
                    '[class.tk-button--size-s]': 'size === "s"',
                    '[class.tk-button--size-m]': 'size === "m"',
                    '[class.tk-button--size-l]': 'size === "l"',
                    '[class.tk-button--size-xl]': 'size === "xl"',
                    '[class.tk-button--theme-primary]': 'theme === "primary"',
                    '[class.tk-button--theme-secondary]': 'theme === "secondary"',
                    '[class.tk-button--theme-ghost]': 'theme === "ghost"',
                    '[class.tk-button--theme-raised]': 'theme === "raised"',
                    '[class.tk-button--theme-inline]': 'theme === "inline"',
                    '[class.tk-button--theme-link]': 'theme === "link"',
                    '[class.tk-button--theme-dashed]': 'theme === "dashed"',
                    '[class.tk-button--non-stretch]': '!stretch',
                    '[class.tk-button--stretch]': 'stretch',
                }, template: "<ng-content></ng-content>\n" }]
    }], function () { return [{ type: i0.ElementRef }, { type: undefined, decorators: [{
                type: Optional
            }, {
                type: Inject,
                args: [ThemeOptions]
            }] }]; }, { size: [{
            type: Input
        }], theme: [{
            type: Input
        }], icons: [{
            type: ContentChildren,
            args: [TkIcon]
        }], inline: [{
            type: Input
        }], stretch: [{
            type: Input
        }] }); })();

const _c0 = ["*"];
class ButtonGroupComponent {
}
ButtonGroupComponent.ɵfac = function ButtonGroupComponent_Factory(t) { return new (t || ButtonGroupComponent)(); };
ButtonGroupComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ButtonGroupComponent, selectors: [["tk-button-group"], ["", "tkButtonGroup", ""]], hostVars: 2, hostBindings: function ButtonGroupComponent_HostBindings(rf, ctx) { if (rf & 2) {
        i0.ɵɵclassProp("tk-button-group", true);
    } }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function ButtonGroupComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonGroupComponent, [{
        type: Component,
        args: [{ selector: 'tk-button-group, [tkButtonGroup]', changeDetection: ChangeDetectionStrategy.OnPush, host: {
                    '[class.tk-button-group]': 'true',
                }, template: "<ng-content></ng-content>\n" }]
    }], null, null); })();

class TkButtonModule {
}
TkButtonModule.ɵfac = function TkButtonModule_Factory(t) { return new (t || TkButtonModule)(); };
TkButtonModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: TkButtonModule });
TkButtonModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkButtonModule, [{
        type: NgModule,
        args: [{
                declarations: [ButtonComponent, ButtonGroupComponent],
                imports: [CommonModule],
                exports: [ButtonComponent, ButtonGroupComponent],
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(TkButtonModule, { declarations: [ButtonComponent, ButtonGroupComponent], imports: [CommonModule], exports: [ButtonComponent, ButtonGroupComponent] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { ButtonComponent, ButtonGroupComponent, TkButtonModule };

