import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MailService } from '@services/mail.service';
import { LoadingService } from '@services/loading.service';
import { DateTime } from 'luxon';
import { takeUntil, tap } from 'rxjs';
import { DestroyService } from '@services/destroy.service';
import { ToastService } from '@services/toast.service';

@Component({
	selector: 'tk-mail',
	templateUrl: './mail.component.html',
	styleUrls: ['/apps/technokratos-admin/src/styles/common.sass', './mail.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailPage {
	emailList$ = this.mailService.emailList$;
	asc = true;

	constructor(
		private mailService: MailService,
		private toast: ToastService,
		public loading$: LoadingService,
		private destroy$: DestroyService,
	) {
		this.refresh();
	}

	get columns(): string[] {
		return ['email', 'created_at'];
	}

	onDownload() {
		this.toast.info('Файл скоро скачается');
		this.mailService
			.exportEmails()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				const blob = res.body as Blob;

				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = `Электронные почты пользователей от ${DateTime.now().toFormat('dd.MM.yyyy')}.xls`;
				link.click();
				link.remove();
			});
	}

	changeSort() {
		this.asc = !this.asc;
		this.refresh();
	}

	refresh() {
		this.mailService
			.getEmails(this.asc ? '-created_at' : 'created_at')
			.pipe(
				tap(() => this.loading$.next(true)),
				takeUntil(this.destroy$),
			)
			.subscribe({
				next: res => {
					this.emailList$.next(res);
					this.loading$.next(false);
				},
				error: err => this.toast.error(err),
			});
	}
}
