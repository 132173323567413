import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Author } from '@models/author';
import { CreateAuthorRequest } from '@models/create-author-request';

@Injectable({
	providedIn: 'root',
})
export class AuthorsService implements OnDestroy {
	private http = inject(HttpClient);

	authorList$ = new BehaviorSubject<Author[]>([]);

	getAuthors(): Observable<Author[]> {
		return this.http.get<Author[]>('admin/blog/authors/');
	}

	getAuthor(id: string): Observable<Author> {
		return this.http.get<Author>(`admin/blog/authors/${id}/`);
	}

	createAuthor(data: CreateAuthorRequest): Observable<Author> {
		return this.http.post<Author>('admin/blog/authors/', data);
	}

	updateAuthor(id: number, data: Partial<CreateAuthorRequest>) {
		return this.http.patch<Author>(`admin/blog/authors/${id}/`, data);
	}

	deleteAuthor(id: number) {
		return this.http.delete<void>(`admin/blog/authors/${id}/`);
	}

	ngOnDestroy() {
		this.authorList$.complete();
	}
}
