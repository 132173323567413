import {
	Directive,
	ElementRef,
	HostBinding,
	HostListener,
	inject,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { customOverlayClass } from '@services/dialog.service';

@Directive({
	standalone: true,
	selector: `[tk-dialog-close], [tkDialogClose]`,
	exportAs: 'tkDialogClose',
})
export class DialogCloseDirective implements OnInit, OnChanges {
	@Input('tk-dialog-close') dialogResult: unknown;
	@Input() tkDialogClose: unknown;
	@Input() confirmationText: string | null = null;

	public dialogRef = inject(DialogRef);
	private elementRef = inject(ElementRef<HTMLElement>);
	private dialog = inject(Dialog);

	@HostBinding('type') get hostType() {
		return 'button';
	}

	ngOnInit(): void {
		if (this.dialogRef) return;

		const dialog = getClosestDialog(this.elementRef, this.dialog.openDialogs);
		if (dialog) {
			this.dialogRef = dialog;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		const proxiedChange = changes['dialogResult'] || changes['tkDialogClose'];

		if (proxiedChange) {
			this.dialogResult = proxiedChange.currentValue;
		}
	}

	@HostListener('click')
	close(): void {
		getClosestDialog(this.elementRef, this.dialog.openDialogs);
		const close = () => this.dialogRef?.close(this.dialogResult);

		if (this.confirmationText !== null && !confirm(this.confirmationText)) {
			return;
		}

		close();
	}
}

export function getClosestDialog(
	element: ElementRef<HTMLElement>,
	openDialogs: readonly DialogRef[],
): DialogRef | null | undefined {
	let parent: HTMLElement | null = element.nativeElement.parentElement;

	while (parent && !parent.classList.contains(customOverlayClass)) {
		parent = parent.parentElement;
	}

	return parent ? openDialogs.find(dialog => dialog.overlayRef.overlayElement === parent) : null;
}
