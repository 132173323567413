<div class="wrapper">
	<div class="logo">
		<tk-icon icon="tk_logo" />
		Технократия
	</div>

	<a [attr.href]="authService.authUrl" theme="secondary" tkButton>
		<tk-icon icon="google" />
		<span>Войти с помощью Google</span>
	</a>
</div>
