import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { Portal } from '@angular/cdk/portal';
import { CdkDialogContainer, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'tk-modal-container',
	templateUrl: './modal-container.component.html',
	styleUrls: ['./modal-container.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{ provide: DIALOG_DATA, useValue: {} },
		{ provide: DialogRef, useValue: {} },
	],
	animations: [
		trigger('openSideBar', [
			transition(':enter', [
				style({ transform: 'translateY(100%)' }),
				animate('500ms ease-in', style({ transform: 'translateY(0%)' })),
			]),
			transition(':leave', [animate('5000ms ease-in', style({ transform: 'translateY(100%)' }))]),
		]),
	],
})
export class ModalContainerComponent extends CdkDialogContainer implements OnInit {
	@HostBinding('@openSideBar')
	portal: Portal<Component> | null = null;

	ngOnInit() {
		this._document.querySelector('.cdk-overlay-container')?.classList.add('cdk-overlay-container--slideUp');
	}
}
