import * as i0 from '@angular/core';
import { InjectionToken, Directive, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

const ThemeOptions = new InjectionToken('ThemeOptions');
class ThemeInvertDirective {
}
ThemeInvertDirective.ɵfac = function ThemeInvertDirective_Factory(t) { return new (t || ThemeInvertDirective)(); };
ThemeInvertDirective.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: ThemeInvertDirective, selectors: [["", "tkThemeInvert", ""]], hostVars: 2, hostBindings: function ThemeInvertDirective_HostBindings(rf, ctx) { if (rf & 2) {
        i0.ɵɵclassProp("tk-theme-invert", true);
    } }, features: [i0.ɵɵProvidersFeature([
            {
                provide: ThemeOptions,
                useValue: { inverted: true },
            },
        ])] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemeInvertDirective, [{
        type: Directive,
        args: [{
                selector: '[tkThemeInvert]',
                providers: [
                    {
                        provide: ThemeOptions,
                        useValue: { inverted: true },
                    },
                ],
                host: {
                    '[class.tk-theme-invert]': 'true',
                },
            }]
    }], null, null); })();

class TkThemeModule {
}
TkThemeModule.ɵfac = function TkThemeModule_Factory(t) { return new (t || TkThemeModule)(); };
TkThemeModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: TkThemeModule });
TkThemeModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TkThemeModule, [{
        type: NgModule,
        args: [{
                declarations: [ThemeInvertDirective],
                imports: [CommonModule],
                exports: [ThemeInvertDirective],
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(TkThemeModule, { declarations: [ThemeInvertDirective], imports: [CommonModule], exports: [ThemeInvertDirective] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { ThemeInvertDirective, ThemeOptions, TkThemeModule };

