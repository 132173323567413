const WIDTH_REGEX = /viewBox=['"]0 0 (\d+) \d+['"]/;
const HEIGHT_REGEX = /viewBox=['"]0 0 \d+ (\d+)['"]/;
export const DEFAULT_ICON_SIZE = 24;

const SymbolRegex = (symbol: string): RegExp => new RegExp(`<symbol[^>]*id="${symbol}"[\\s\\S]*?<\/symbol>`, 'g');
const ViewBoxRegex = /viewBox=['"](.*?)['"]/;

export const getSize = (source: string): [number | null, number | null] => {
	const matchedWidth = source.match(WIDTH_REGEX);
	const matchedHeight = source.match(HEIGHT_REGEX);

	if (!matchedWidth || !matchedHeight) {
		return [null, null];
	}

	return [Number(matchedWidth[1]), Number(matchedHeight[1])];
};

export const symbolToSvg = (response: string, mappedSrc: string) => {
	const use = mappedSrc.split('#')[1];
	const matched = response.match(SymbolRegex(use));

	if (!matched?.length) {
		return null;
	}

	const matchedViewBox = matched[0].match(ViewBoxRegex);
	if (!(matchedViewBox && matchedViewBox[1])) {
		return null;
	}

	return `<svg
			version='1.1'
			xmlns='http://www.w3.org/2000/svg'
			focusable='false'
			viewBox='${matchedViewBox[1]}'
		>
			<use xlink:href='${mappedSrc}'></use>
		</svg>`;
};

const cache = new Map<string, Promise<string>>();

export const cachedRequest = (url: string): Promise<string> => {
	const urlBase = url.split('#')[0];
	const cached = cache.get(urlBase);

	if (cached) {
		return cached;
	}

	const promise = new Promise<string>((resolve, reject) => {
		const xhr = new XMLHttpRequest();

		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				const response = xhr.responseType ? xhr.response : xhr.responseText;

				if (xhr.status === 200) {
					resolve(response);
				} else {
					reject(response);
				}
			}
		};
		xhr.open('GET', urlBase);
		xhr.send();

		return () => {
			xhr.abort();
		};
	});

	cache.set(urlBase, promise);

	return promise;
};
