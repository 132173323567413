import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	standalone: true,
	selector: 'tk-chip',
	templateUrl: './chip.component.html',
	styleUrls: ['./chip.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {}
