<input
	type="checkbox"
	class="checkbox__input"
	id="custom-checkbox-label"
	[checked]="value"
	(change)="handleOnChange($event)"
/>
<label for="custom-checkbox-label" class="checkbox__label">
	<ng-content></ng-content>
</label>
